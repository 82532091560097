import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    group: {
      display: 'inline-flex',
      padding: 10,
      '& .label': {
        display: 'flex',
        border: `2px solid ${props.color2}`,
        borderRadius: '4px 0px 0px 4px',
        flexGrow: 1,
        borderRight: 'none',
        height: 28,
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 400,
        color: props.color1,
        paddingLeft: 20,
        paddingRight: 20
      },
      '& .button': {
        cursor: 'pointer',
        display: 'flex',
        border: `2px solid ${props.color2}`,
        borderRadius: '0px 4px 4px 0px !important',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 6px',
        background:props.color2,
        height: 32,
        fontSize: 14,
        color: props.color6,
        outline: 'none'
      },
      '& .disabled': {
        cursor: 'not-allowed !important',
      }
    }
  }));
  return styles();
}
export default theme;

