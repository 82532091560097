import React, { Fragment, memo, useState } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { disableSupplierConnection, } from '../../../redux/actions/supplierInvoices';
import translate from '../../../misc/applyTranslation';
import toMoney from '../../../misc/serializeToMoney';
import { BorderButton } from '../../buttons';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {useConfig} from "../../../context/configContext";

const STELInvoicesDetail = (props) => {
  const params = useParams();
  const config = useConfig();
  const classes = theme(config);
  const [linkBrakeConfirm, setLinkBrakeConfirm] = useState(false)
  const {
    batch_data,
    supplier_invice_data,
    disableSupplierConnection,
    access_token,
    refresh_token,
    customer_data,
    supplier_data,
  } = props;

  const handleDisableSupplierConnection = () => {
    disableSupplierConnection({
      id: params.id,
      access_token,
      refresh_token,
      customer_id: customer_data.id,
      supplier_id: supplier_data.id,
      customer_nr_at_supplier: supplier_invice_data.cust_nr_at_supplier
    })
  }
  return (
    <Fragment>
      <div className={classes.titleGray}>{translate('supplier-invoices/detail', 'customer_panel_stel_invoice')} <span>{translate('supplier-invoices/detail',
        batch_data.length === 0 ? 'customer_panel_not_invoiced_placeholder' : 'customer_panel_invoiced_placeholder'
      )} </span></div>
      <div className={classes.columnContainer}>
        <div className='columnlabel'>
          <div className={classes.text}>{translate('supplier-invoices/detail', 'customer_panel_stel_invoice_day_label')}</div>
          <div className={classes.text}>{translate('supplier-invoices/detail', 'customer_panel_stel_due_day')}</div>
          <div className={classes.text}>{translate('supplier-invoices/detail', 'customer_panel_stel_total_amount_label')}</div>
        </div>
        <div className='columnvalue'>
          <div className={classes.text}>{moment.unix(batch_data['inv_day']).format('YYYY-MM-DD') || '---'}</div>
          <div className={classes.text}>{moment.unix(batch_data['due_day']).format('YYYY-MM-DD') || '---'}</div>
          <div className={classes.text}>{supplier_invice_data['amount_total'] ? toMoney(supplier_invice_data['amount_total']) : '---'}</div>
        </div>
      </div>
      {/* {allowConnectionBrake && */}
      <div className={classes.fixMarginConnection}>
        <BorderButton text={translate('supplier-invoices/detail', 'customer_panel_clear_customer_connection')} onClick={() => setLinkBrakeConfirm(true)} />
        {linkBrakeConfirm &&
          <React.Fragment>
            <p>{translate('supplier-invoices/detail', 'customer_panel_confirm_message')}</p>
            <div className='confirmArea'>
              <BorderButton text='Confirm' onClick={handleDisableSupplierConnection} />
              <BorderButton text='Cancel' onClick={() => setLinkBrakeConfirm(false)} />
            </div>
          </React.Fragment>
        }
      </div>
      {/* } */}
    </Fragment >
  );
}
STELInvoicesDetail.propTypes = {
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  batch_data: store.supplierInvoices.supplierInvoiceDetail.batch_data,
  supplier_invice_data: store.supplierInvoices.supplierInvoiceDetail.supplier_invice_data,
  supplier_data: store.supplierInvoices.supplierInvoiceDetail.supplier_data,
  customer_data: store.supplierInvoices.supplierInvoiceDetail.customer_data,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    disableSupplierConnection,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(STELInvoicesDetail));