import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    chip: {
      padding: '4px 15px',
      fontSize: 11,
      fontWeight: 200,
      borderRadius: 5,
      textTransform: 'uppercase',
      color: 'white',
    },
    green: {
      backgroundColor: '#5ba26f'
    },
    red: {
      backgroundColor: '#ff524d'
    },
    grey: {
      backgroundColor: '#A0A0A0'
    },
    blue: {
      backgroundColor: props.color2
    },
    border_blue: {
      border: `2px solid ${props.color3}`,
      color: props.color2,
      cursor: 'pointer',
    },
  }));
  return styles();
}
export default theme;

