import React from 'react';
import theme from './style'
import PropTypes from 'prop-types';
import {
    DatePicker as DP,
} from '@material-ui/pickers';
import CheckIcon from '@material-ui/icons/Check';
import {useConfig} from "../../../context/configContext";

const DatePicker = (props) => {
  
  const config = useConfig();
  const classes = theme(config);
  const { style, value, onChange, checkIcon } = props;
  const [selectedDate, setSelectedDate] = React.useState(value);
  return (
    <div className={classes.datepickerContainer} style={style ? style : {}}>
      <DP
        className={classes.datepicker}
        disableToolbar
        autoOk
        format="YYYY-MM-DD"
        id="date-picker-inline"
        value={selectedDate}
        onChange={e => {
          setSelectedDate(e);
          if(!checkIcon) {
            onChange(e);
          }
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
      {checkIcon && <CheckIcon className='button' onClick={() => onChange(selectedDate)}/>}
    </div>
  );
}
DatePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
}
export default DatePicker;