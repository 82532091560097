import React from 'react';
import theme from './style'
import {TextField} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx'
import {useConfig} from "../../../context/configContext";

const TextInputWNote = (props) => {
    const config = useConfig();
    const classes = theme(config);
    const {placeholder, value, onChange, note, disabled, invalid} = props;
    return (
        <div className={clsx(classes.textinputContainer, invalid ? classes.invalid : '')}>
            <TextField
                disabled={disabled}
                onChange={(e) => {
                    if (onChange) onChange(e)
                }}
                className={`textinput`}
                placeholder={placeholder}
                value={value}
            />
            <div className={'note'}>{note}</div>
        </div>
    );
}
TextInputWNote.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    note: PropTypes.string,
    disabled: PropTypes.bool,

}
export default TextInputWNote;