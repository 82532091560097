import React, {memo} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import theme from "./style";
import translate from "../../../misc/applyTranslation";
import moment from "moment/moment";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";

const CustomerRecurringFees = props => {
  const classes = theme();

  const {onClick, onEdit, onDelete, data} = props;

  return (
    <React.Fragment>
      <TableContainer component="div" className={classes.container}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>{translate('edit-recurring-fee', 'table_header_fee_name')}</TableCell>
              <TableCell>{translate('edit-recurring-fee', 'table_header_account_nr')}</TableCell>
            {/*  <TableCell>{translate('edit-recurring-fee', 'table_header_amount')}</TableCell>
              <TableCell>{translate('edit-recurring-fee', 'table_header_vat')}</TableCell>*/}
              <TableCell>{translate('edit-recurring-fee', 'table_header_fee')}</TableCell>
              <TableCell>{translate('edit-recurring-fee', 'table_header_fee_vat')}</TableCell>
              <TableCell>{translate('edit-recurring-fee', 'table_header_vat_recurring')}</TableCell>
              <TableCell>{translate('edit-recurring-fee', 'table_header_start_date')}</TableCell>
              <TableCell>{translate('edit-recurring-fee', 'table_header_end_date')}</TableCell>
              <TableCell>{translate('edit-recurring-fee', 'table_header_edit')}</TableCell>
              <TableCell>{translate('edit-recurring-fee', 'table_header_delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map(row => (
              <TableRow key={row.id} onClick={() => {
                if (onClick) {
                  onClick(row.id)
                }
              }}>
                <TableCell>{row.fee_name}</TableCell>
                <TableCell>{row.accountnumber}</TableCell>
                <TableCell>{row.fee_amount + " kr"}</TableCell>
                {/*<TableCell>{row.fee_amount + " kr"}</TableCell>*/}
                {/*<TableCell></TableCell>*/}
                <TableCell>{row.fee_vat + " kr"}</TableCell>
                <TableCell>{row.recurring}</TableCell>
                <TableCell>{row.start_date ? moment.unix(row.start_date).format('YYYY-MM-DD') : "-"}</TableCell>
                <TableCell>{row.end_date ? moment.unix(row.end_date).format('YYYY-MM-DD') : "-"}</TableCell>
                <TableCell align="center">
                  <EditIcon style={{cursor: "pointer"}}
                    onClick={() => {
                    onEdit(row)
                  }}/>
                </TableCell>
                <TableCell align="center">
                  <CloseIcon style={{cursor: "pointer", fill: "red"}}
                    onClick={() => {
                    onDelete(row.id);
                  }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

export default memo(CustomerRecurringFees);