import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    container: {
      width: 'calc(100% - 20px)',
      marginBottom: 20,
      '& .pointer': {
        cursor: 'pointer'
      }
    },
    table: {
      minWidth: 650,
      width: 'calc(100% - 50px)',
      margin: '15px 25px',
      '& .MuiTableCell-head': {
        color: '#989898',
        fontSize: 13,
        fontWeight: 400,
        borderBottomWidth: 2,
      },
      '& .MuiTableCell-body': {
        color: '#989898',
        fontSize: '13px !important',
        fontWeight: 200,
        '& svg': {
          fontSize: '13px !important',
          fill: '#989898'
        }
      },
    },
    importListItem: {
      color: `${props.color2} !important`,
      fontWeight: '400 !important',
      opacity: '0.8',
    },
    tableTitle: {
      color: '#989898',
      fontWeight: 400,
      padding: 10,
      fontSize: 20,
      textTransform: 'uppercase',
      '& span': {
        color:`${props.color2} !important`,
      }
    },
    titleContainer: {
      width: '100%',
      paddingTop: 20,
      paddingBottom: 10,
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    titleDateFilter: {
      display: 'inline-flex',
      alignContent: 'center',
      '& p': {
        display: 'flex',
        alignItems: 'center',
        height: 34,
        margin: 0,
        color: '#989898',
        fontSize: 13,
      },
    }
  }));
  return styles();
}
export default theme;

