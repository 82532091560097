
import React, { memo, } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BorderButton, } from '../../components/buttons'
import { TextInput, } from '../../components/inputs'
import { Grid, Paper } from '@material-ui/core';
import { useHistory, useParams, withRouter } from "react-router-dom";
import translate from '../../misc/applyTranslation'
import {useConfig} from "../../context/configContext";

const AuthBankID = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const history = useHistory();
  const params = useParams();
  return (
    <form onSubmit={(e) => e.preventDefault()} className={classes.formContainer}>
      <Grid container className={'gridContainer'}>
        <Grid item xs={11} sm={9} md={7} lg={5} className={'gridItemContainer'}>
          {/* <Paper className={'logoContainer'}>
            <img src={settings.logoAdmin} alt='logo' />
          </Paper> */}
          <div className={'inputGroup'}>
            <TextInput border placeholder= {translate('login','placeholder_email')} />
            <BorderButton text= {translate('login','button_recover')} onClick={() => console.log('a')} />
          </div>
        </Grid>
        <Grid item xs={12} className={'navigationContainer'}>
          <div className={'navigationURL'} onClick={() => history.push(`/${params.lang}`)}>
          {translate('login','link_bankid_login')}
          </div>
          <div className={'navigationURL'} onClick={() => history.push(`/${params.lang}/login`)}>
          {translate('login','link_forgot_password')}
          </div>
        </Grid>
      </Grid>
    </form>
  )
}
AuthBankID.propTypes = {
}
const mapStateToProps = store => ({
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(memo(AuthBankID)));