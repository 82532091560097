import React, { memo, useEffect, useState } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { TextField } from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { fetchNotesList, patchNote, createNote } from '../../redux/actions/supplierInvoices';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import translate from '../../misc/applyTranslation'
import {useConfig} from "../../context/configContext";
const DialogBox = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const params = useParams();
  const {
    fetchNotesList,
    patchNote,
    createNote,
    notesList,
    access_token,
    refresh_token,
    email,
    user_id
  } = props;

  const [editState, setEditState] = useState({
    id: null,
    text: '',
  })
  const [newNote, setNewNote] = useState('')
  useEffect(() => {
    fetchNotesList({
      access_token,
      refresh_token,
      id: params.id,
    })
    // eslint-disable-next-line
  }, [])

  function isOdd(num) { return num % 2; }

  const startEdit = (id, text) => {
    setEditState({ id, text })
  }
  const saveChanges = () => {
    if (editState.text.length > 0) {
      patchNote({
        access_token,
        refresh_token,
        id: params.id,
        note_id: editState.id,
        text: editState.text
      })
      setEditState({
        id: null,
        text: '',
      })
    }
  }
  const saveNewNote = (e) => {
    try { e.preventDefault() } catch (error) { }
    if (newNote.length > 0) {
      createNote({
        access_token,
        refresh_token,
        id: params.id,
        text: newNote
      })
      setEditState({
        id: null,
        text: '',
      })
      setNewNote('')
    }
  }
  useEffect(() => {
    try {
      let ele = document.getElementById('noteContainer');
      ele.scrollTo(0, ele.scrollHeight);
    } catch (error) { }
  }, [notesList])
  return (
    <div className={classes.container}>
      <h3 className={classes.dialogTitle}>{translate('supplier-invoices/detail', 'notes_panel_title')}</h3>
      <div className={classes.dialogContainer} id='noteContainer'>
        {notesList.map((item, ind) => {
          if (item.note && item.note.length > 0) {
            return <div key={item.id} className={classes.dialogMessage}>
              <div className={`username ${isOdd(ind) ? 'blue' : ''}`} >{item.owner_name}<div className='line' /></div>
              {user_id === item.owner && <div className={classes.iconHolder}>
                {item.id !== editState.id &&
                  <EditIcon className={'icon edit'} onClick={() => { startEdit(item.id, item.note) }} />
                }
                {item.id === editState.id &&
                  <SaveIcon className={'icon save'} onClick={saveChanges} />
                }
                {item.id === editState.id &&
                  <CloseIcon className={'icon close'} onClick={() => { startEdit(null, '') }} />
                }
              </div>}
              <div className='message'>
                {item.id !== editState.id && <div>{item.note}</div>}
                {item.id === editState.id && <TextField
                  className={classes.textinputInline}
                  value={editState.text}
                  onChange={(e) => setEditState({ ...editState, text: e.target.value })}
                />}
              </div>
              {(!item.mdate || item.cdate === item.mdate) &&
                <div className={`date ${isOdd(ind) ? 'blue' : ''}`}>{moment(item.cdate).format('YYYY-MM-DD')}</div>
              }
              {item.mdate && item.cdate !== item.mdate &&
                <div className={`spaceBetween ${isOdd(ind) ? 'blue' : ''}`}>
                  <div>{`(EDITED_AT ${moment(item.mdate).format('YYYY-MM-DD')})`}</div>
                  <div>{moment(item.cdate).format('YYYY-MM-DD')}</div>
                </div>
              }
            </div>
          } else {
            return false
          }
        }
        )}


      </div>
      <form onSubmit={saveNewNote} >
        <div className={classes.chatInputContainer}>
          <div className='username'>{email}:</div>
          <TextField
            className={classes.textinput}
            placeholder='Type something...'
            value={newNote}
            onChange={(e) => { setNewNote(e.target.value) }}
          />
          <PlayCircleOutlineIcon onClick={saveNewNote} />
        </div>
      </form>
    </div >
  );
}
DialogBox.propTypes = {
  onClick: PropTypes.func.isRequired,
  fetchNotesList: PropTypes.func.isRequired,
  patchNote: PropTypes.func.isRequired,
  createNote: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  notesList: store.supplierInvoices.notesList,
  email: store.login.userData.email,
  user_id: store.login.userData.user_id,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchNotesList,
    patchNote,
    createNote,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(DialogBox));