import { makeStyles } from "@material-ui/core/styles";
const theme = (props) => {
  const styles = makeStyles(theme => ({
    button: {
      width: 'calc(100% - 20px)',
      borderRadius: 4,
      background: 'white',
      borderColor: props.color2,
      color: props.color1,
      borderWidth: 2,
      fontWeight: 400,
      fontSize: 14,
      textTransform: 'none',
      '&:hover': {
        background: props.rgba,
      },
      '& span': {
        height: 20,
      },
      '& svg': {
        fontSize: '16px !important'
      },
      margin: '0px 10px',

    },
    warning: {
      borderColor: '#a94442 !important',
      color: '#a94442 !important',
    },
    success: {
      borderColor: '#5cb85c !important',
      color: '#5cb85c !important',
    },

    active: {
      color: props.color6,
      background: props.color3,
    },
    disabled: {
      cursor: 'not-allowed',
    }
  }));
  return styles();
}
export default theme;

