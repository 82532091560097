import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    group: {
      display: 'inline-flex',
      width: 'calc(100% - 20px)',
      padding: 10,
      '& .label': {
        display: 'flex',
        border: '1px solid #ccc',
        borderRadius: '4px 0px 0px 4px',
        flexGrow: 1,
        borderRight: 'none',
        height: 28,
        alignItems: 'center',
        fontSize: 13,
        fontWeight: 400,
        color: props.color1,
        paddingLeft: 10
      },
      '& .button': {
        cursor: 'pointer',
        display: 'flex',
        border: '1px solid #ccc',
        borderRadius: '0px 4px 4px 0px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 6px',
        background: props.color3,
        height: 28,
        '& svg': {
          fontSize: 18,
          fill: 'white'
        }
      }
    }
  }));
  return styles();
}
export default theme;

