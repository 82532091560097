import React, { Fragment, memo } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import translate from '../../../misc/applyTranslation';
import { BorderButton } from '../../../components/buttons';
import { TextInput, TextArea, SingleCheck, } from '../../../components/inputs';
import {
  dismissSupplierInvoice,
  deleteSupplierInvoice,
} from '../../../redux/actions/supplierInvoices';
import { useParams } from 'react-router-dom';
import {useConfig} from "../../../context/configContext";
const MessageBox = (props) => {
  const params = useParams();
  const config = useConfig();
  const classes = theme(config);
  const {
    // access_token,
    // refresh_token,
  } = props;

  return (
    <Fragment>
      <div className={classes.title}>{translate('supplier-invoices/detail', 'message_panel_title')}</div>
      <div className={classes.text}>{translate('supplier-invoices/detail', 'dismiss_panel_send_message_label')}</div>
      <div className={classes.group}>
        <div className={classes.input}>
          <TextInput value={'alwan_27@hotmail.com'} />
        </div>
      </div>
      <div className={classes.group}>
        <div className={classes.input}>
          <TextInput placeholder={translate('supplier-invoices/detail', 'dismiss_panel_customer_message_title_placeholder')} value={''} />
        </div>
        <div className={classes.action}></div>
      </div>
      <div className={classes.group}>
        <TextArea placeholder={translate('supplier-invoices/detail', 'dismiss_panel_customer_message_placeholder')} />
      </div>
      <div className={classes.group}>
        <div className={classes.input}>
          <SingleCheck value={false} text={translate('supplier-invoices/detail', 'dismiss_panel_attach_invoice_label')} />
        </div>
        <div className={classes.action}>
          <BorderButton onClick={e => console.log('asd')} text={translate('', 'button_send_message')} />
        </div>
      </div>

    </Fragment >
  );
}
MessageBox.propTypes = {
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    dismissSupplierInvoice,
    deleteSupplierInvoice,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(MessageBox));