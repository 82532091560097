import React from 'react';
import theme from './style'
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import {useConfig} from "../../context/configContext";


const Label = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const { text, } = props;
  return (
    <Typography
      className={classes.label}
    >{text}</Typography>
  );
}
Label.propTypes = {
  text: PropTypes.string.isRequired,
}
export default Label;