import React from 'react';
import theme from './style'
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import {useConfig} from "../../../context/configContext";


const NewButton = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const { text, onClick } = props;
  return (
    <Button
      onClick={(e) => { if (onClick) onClick(e) }}
      className={classes.button}
      variant="outlined"
      startIcon={<ArrowBackIcon />}
    >{text}</Button>
  );
}
NewButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}
export default NewButton;