
import React, { memo, useState, useEffect } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { setSelectedMenu } from '../../redux/actions/states'

import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import { Home as HomeIcon, Person as PersonIcon } from '@material-ui/icons';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { useHistory, useParams, withRouter } from "react-router-dom";
import translate from '../../misc/applyTranslation';
import {useConfig} from "../../context/configContext";
const DrawerMenu = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const {
    setSelectedMenu,
    selectedMenu,
    closeMenu
  } = props;
  const [open, setOpen] = useState(null);
  const history = useHistory();
  const params = useParams();
  const handleClick = (id, event) => {
    if (open === id) {
      setOpen(null)
    } else {
      setOpen(id)
    }
  };
  const handleNavigation = (id) => {
    switch (id) {
      case 0:
        history.push(`/${params.lang}`)
        break;
      case 1:
        history.push(`/${params.lang}/customers`)
        break;
      case 2:
        history.push(`/${params.lang}/customers/add-customer`)
        break;
      case 3:
        history.push(`/${params.lang}/supplier-invoices`)
        break;
      case 4:
        history.push(`/${params.lang}/suppliers`)
        break;
      case 5:
        history.push(`/${params.lang}/invoices`)
        break;
      case 6:
        history.push(`/${params.lang}/files`)
        break;
      default:
        break;
    }
    setSelectedMenu(id)
    closeMenu();
  }

  useEffect(() => {
    if ((selectedMenu === 1 || selectedMenu === 2)) {
      if (open !== '0') setOpen('0')
    } else if (selectedMenu === 4) {
      if (open !== '1') setOpen('1')
    } else {
      setOpen(null)
    }
    // eslint-disable-next-line
  }, [selectedMenu])
  return (
    <div className={classes.drawerContainer}>
      <List>

        <ListItem button className={selectedMenu === 0 ? classes.selected : ''} onClick={() => handleNavigation(0)}>
          <ListItemIcon><HomeIcon /> </ListItemIcon>
          <ListItemText primary={translate('', 'menu_home')} />
        </ListItem>

        <ListItem button className={(selectedMenu === 1 || selectedMenu === 2 || open === '0') ? classes.selectedMain : ''} onClick={handleClick.bind(null, '0')}>
          <ListItemIcon><PersonIcon /></ListItemIcon>
          <ListItemText primary={translate('', 'menu_customers')} />
        </ListItem>
        <Collapse in={open === '0'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={`${classes.nested} ${selectedMenu === 1 ? classes.selectedNested : ''}`} onClick={() => handleNavigation(1)}>
              <ListItemText primary={translate('', 'menu_search_customers')} />
            </ListItem>
            <ListItem button className={`${classes.nested} ${selectedMenu === 2 ? classes.selectedNested : ''}`} onClick={() => handleNavigation(2)}>
              <ListItemText primary={translate('', 'menu_add_customers')} />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button className={selectedMenu === 3 ? classes.selected : ''} onClick={() => handleNavigation(3)}>
          <ListItemIcon><img alt='' src={selectedMenu === 3 ? '/box-reverse.png' : "/box.png"} /> </ListItemIcon>
          <ListItemText primary={translate('', 'menu_supplier_invoices')} />
        </ListItem>


        <ListItem button className={selectedMenu === 4 ? classes.selected : ''} onClick={() => handleNavigation(4)}>
          <ListItemIcon><img alt='' src={selectedMenu === 4 ? '/factory-reverse.png' : "/factory.png"} /> </ListItemIcon>
          <ListItemText primary={translate('', 'menu_suppliers')} />
        </ListItem>

        <ListItem button className={selectedMenu === 5 ? classes.selected : ''} onClick={() => handleNavigation(5)}>
          <ListItemIcon><img alt='' src={selectedMenu === 5 ? '/invoice-reverse.png' : '/invoice.png'} /> </ListItemIcon>
          <ListItemText primary={translate('', 'menu_invoicing')} />
        </ListItem>

        <ListItem button className={selectedMenu === 6 ? classes.selected : ''} onClick={() => handleNavigation(6)}>
          <ListItemIcon><InsertDriveFileIcon /> </ListItemIcon>
          <ListItemText primary={translate('', 'menu_files')} />
        </ListItem>
      </List>
    </div>
  )
}
DrawerMenu.propTypes = {
  setSelectedMenu: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  selectedMenu: PropTypes.number.isRequired,
}
const mapStateToProps = store => ({
  selectedMenu: store.state.selectedMenu,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ setSelectedMenu }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(memo(DrawerMenu)));
