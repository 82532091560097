import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    tableTitle: {
      color: props.color2,
      fontWeight: 400,
      fontSize: 20,
      textTransform: 'uppercase',
      padding: '20px 20px 10px 20px',
    },
    container: {
      width: 'calc(100% - 20px)',
      marginBottom: 20,
    },
    table: {
      minWidth: 650,
      width: 'calc(100% - 50px)',
      margin: '15px 25px',
      '& .MuiTableCell-head': {
        color: '#989898',
        fontSize: 13,
        fontWeight: 400,
        borderBottomWidth: 2,
      },
      '& .MuiTableCell-body': {
        color: '#989898',
        fontSize: '13px !important',
        fontWeight: 200,
        '& svg': {
          fontSize: '13px !important',
          fill: '#989898'
        }
      },
      '& .MuiTableRow-root': {
        cursor: 'pointer'
      }
    },
    importListItem: {
      color: `${props.color2} !important`,
      fontWeight: '400 !important',
      opacity: '0.8',
    },
    reminderWrap: {
      display: "flex",
      alignItems: "center"
    }
  }));
  return styles();
}
export default theme;

