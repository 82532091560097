import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    title: {
      color: props.color2,
      fontSize: 20,
      fontWeight: 400,
      marginBottom: 10,
    },
    borderdStatus: {
      color: '#989898',
      border: '1px solid #ccc',
      padding: 10,
      marginBottom: 10,
      borderRadius: 4,
      fontSize: 13,
      fontWeight: 400,
      '& span': {
        textTransform: 'uppercase',
        color: 'gray',
      }
    },
    simpleStatus: {
      color: '#989898',
      padding: '10px 0px',
      fontSize: 16,
      fontWeight: 200,
      '& span': {
        textTransform: 'uppercase',
        color: 'green',
      }
    },
    fixMargin: {
      width: 'calc(100% + 20px)',
      marginLeft: -10,
    },
  }));
  return styles();
}
export default theme;

