import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    container: {
      width: 'calc(100%)',
      marginBottom: 20,
    },
    cursor: {
      cursor: 'pointer'
    },
    table: {
      minWidth: 650,
      width: 'calc(100%)',
      '& .specialHeader': {
        '& tr :last-child': {
          borderRadius: '0px 4px 0px 0px',
        },
        '& tr :first-child': {
          borderRadius: '4px 0px 0px 0px',
        },
        '& th': {
          background: props.color3,
          color: `${props.color6} !important`,
          textAlign: 'center',
          border: 'none !important'
        }
      },
      '& .MuiTableCell-root': {
        '&:nth-child(7) , &:nth-child(10)': {
          borderRight: `1px solid ${props.color5}`
        },
      },
      '& .MuiTableCell-head': {
        color: '#989898',
        fontSize: 13,
        fontWeight: 400,
        borderBottomWidth: 2,

      },
      '& .MuiTableCell-body': {
        color: '#989898',
        fontSize: '13px !important',
        fontWeight: 200,
        '& svg': {
          fontSize: '13px !important',
          fill: '#989898'
        }
      },
    },
    importListItem: {
      color: `${ props.color2 }!important`,
      fontWeight: '400 !important',
      opacity: '0.8',
    },
    tableTitle: {
      color: '#989898',
      fontWeight: 400,
      padding: 10,
      fontSize: 20,
      textTransform: 'uppercase',
      '& span': {
        color: `${ props.color2 }!important`,
      }
    },
    titleContainer: {
      width: '100%',
      paddingTop: 20,
      paddingBottom: 10,
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    titleDateFilter: {
      display: 'inline-flex',
      alignContent: 'center',
      '& p': {
        display: 'flex',
        alignItems: 'center',
        height: 34,
        margin: 0,
        color: '#989898',
        fontSize: 13,
      },
    }
  }));
  return styles();
}
export default theme;

