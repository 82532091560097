import React, { memo } from 'react';
import theme from './style';
import APICall from "../../../misc/APICall";
import PropTypes from 'prop-types';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';
import StatusChips from '../../StatusChip';
import { DatePicker } from '../../inputs'
import moment from 'moment';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import toMoney from '../../../misc/serializeToMoney';
import translate from '../../../misc/applyTranslation';
import {bindActionCreators} from "redux";
import {
  fetchInvoicePDF
} from "../../../redux/actions/customer/customerDetail";
import {connect} from "react-redux";
import {useConfig} from "../../../context/configContext";

const OutboundInvoice = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const {
    title,
    data,
    onProlong,
    access_token
  } = props;

  const handlePDF = (e, id) => {
    e.stopPropagation();
    fetchInvoicePDF({access_token: access_token, id: id})
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper} className={classes.container} >
        <div className={classes.titleContainer}>
          {title && <Typography className={classes.tableTitle}>{translate('customers/detail', 'invoices_table_title_customer')} <span>{title}</span></Typography>}
          <div className={classes.titleDateFilter}>
            <p>{translate('customers/detail', 'supplier_invoices_detail_prolong_label')}</p>
            <DatePicker checkIcon value={moment.unix(data[0].inv_due)} style={{ width: 160 }} onChange={onProlong} />
          </div>
        </div>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>{translate('customers/detail', 'invoices_table_detail_outbound_supplier_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'invoices_table_detail_outbound_type_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'invoices_table_detail_outbound_invoice_number_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'invoices_table_detail_outbound_invoice_day_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'invoices_table_detail_outbound_due_day_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'invoices_table_detail_outbound_amount_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'invoices_table_detail_outbound_reminder_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'invoices_table_detail_outbound_inv_ocr_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'invoices_table_detail_outbound_payed_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'invoices_table_detail_outbound_view_header')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{config.company}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.ocr}</TableCell>
                <TableCell>{moment.unix(row.inv_day).format('YYYY-MM-DD')}</TableCell>
                <TableCell>{moment.unix(row.inv_due).format('YYYY-MM-DD')}</TableCell>
                <TableCell>{toMoney(row.total_sum)}</TableCell>
                <TableCell>
                  {row.inv_rem >= '1' && row.inv_ink === '0' &&
                  <div className={classes.reminderWrap}>
                    <WarningIcon style={{fill: '#ff9800', marginRight: 5}}/>
                    {moment.unix(row.inv_rem).format('YYYY-MM-DD')}
                  </div>
                  }
                  {row.inv_ink >= '1' &&
                  <div className={classes.reminderWrap}>
                    <ErrorIcon style={{fill: '#f44336', marginRight: 5}}/>
                    {moment.unix(row.inv_ink).format('YYYY-MM-DD')}
                  </div>
                }
                </TableCell>
                <TableCell>{row.ocr}</TableCell>
                <TableCell><StatusChips type={row.inv_paid && row.inv_paid !== '0' ? 'green' : 'red'} label={row.inv_paid && row.inv_paid !== '0' ? moment.unix(row.inv_paid).format('YYYY-MM-DD') : translate('', 'string_paid_type_false')} /> </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align='center' onClick={(e) => handlePDF(e, row.ocr)}><img src='/pdf-file.png' alt='PDF' /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
OutboundInvoice.propTypes = {
  data: PropTypes.array.isRequired,
  access_token: PropTypes.string.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchInvoicePDF
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(memo(OutboundInvoice));
