import React, { Fragment, memo, useState } from 'react';
import { Paper, Grid } from '@material-ui/core';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { BorderButton } from '../../buttons';
import { TextInput, TextInputWNote, SingleCheck, CustomerConnectionSearch } from '../../inputs';
import Label from '../../label';
import Calendar from '../../Calendar';
import { submitConnectionDetail } from '../../../redux/actions/customer/customerConnection';
import translate from '../../../misc/applyTranslation';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {useConfig} from "../../../context/configContext";
const SupplierConnection = (props) => {
	const params = useParams();
	const config = useConfig();
	const classes = theme(config);
	const { supplier, access_token, refresh_token, submitConnectionDetail, cust_nr_at_supplier } = props;
	const [ formData, setFormData ] = useState({
		customer_id: undefined,
		supplier_id: undefined,
		fee: false,
		fee_vat: 0,
		customer_nr_at_supplier: cust_nr_at_supplier,
		auto_release_value: undefined,
		auto_release_value_check: true,
		fee_start_date_calendar: false,
		fee_start_date: moment()
	});
	const [ selectedCustomer, setSelectedCustomer ] = useState();
	const { id, suppl_org, suppl_bg, suppl_name } = supplier;

	const [ invalidFields, setInvalidFields ] = useState([]);
	const validateFields = () => {
		const fields = [ 'customer_nr_at_supplier', 'fee_vat' ];
		const { auto_release_value_check, auto_release_value } = formData;
		let tempInvFields = [];
		if (!selectedCustomer) {
			tempInvFields.push('selectedCustomer');
		}
		fields.forEach((field) => {
			let value = formData[field];
			if (field === 'fee_vat') {
				if (isNaN(value) || value===''){
					tempInvFields.push(field);
        }
			} else if (!formData[field]) {
				tempInvFields.push(field);
			}
		});
		if (auto_release_value_check && (!auto_release_value || !auto_release_value.length)) {
			tempInvFields.push('auto_release_value');
		}
		return tempInvFields;
	};
	const handleSubmit = (e) => {
		try {
			e.preventDefault();
		} catch (error) {}
		let vF = validateFields();
		if (vF.length > 0) {
			setInvalidFields(vF);
		} else {
			var formdata = new FormData();
			formdata.append('customer_id', selectedCustomer);
			formdata.append('supplier_id', id);
			formdata.append('customer_nr_at_supplier', formData.customer_nr_at_supplier);
			formdata.append('fee_vat', formData.fee_vat || '0');
			formdata.append('fee', formData.fee_vat || '0');
			formdata.append('fee_start_date', !formData.fee_start_date_calendar ? formData.fee_start_date : '0');
			formdata.append('check_auto_pay', formData.auto_release_value_check ? '1' : '0');
			if (formData.auto_release_value_check) {
				formdata.append('auto_release_value', Number(formData.auto_release_value) * 100 || 0);
			}
			submitConnectionDetail({
				id: params.id,
				access_token,
				refresh_token,
				supplier_id: id,
				customer_id: selectedCustomer,
				custnr_supplier: formData.customer_nr_at_supplier,
				verb: 'POST',
				body: formdata,
				supplierForm: true
			});
		}
	};
	const handleSelectCustomer = (id) => {
		setSelectedCustomer(id);
	};

	return (
		<React.Fragment>
			<Paper className={classes.clientBox}>
				<Grid container>
					{/* {!selectedCustomer && */}
					<Grid item xs={12} md={12} style={{ marginBottom: 20 }}>
						<div className={classes.titleGray}>
							{translate('supplier-invoices/detail', 'customer_panel_customer_title')}
						</div>
						<CustomerConnectionSearch
							invalid={invalidFields.includes('customer_nr_at_supplier')}
							onChange={handleSelectCustomer}
						/>
					</Grid>
					{/* } */}
					<form onSubmit={handleSubmit}>
						<Grid container>
							<Grid item xs={12} md={6} style={{ marginBottom: 20 }}>
								<React.Fragment>
									<Label
										text={translate(
											'supplier-invoices/detail',
											'add_supplier_panel_supplier_label'
										)}
									/>
									<TextInput disabled value={suppl_name} />
									<Label
										text={translate('supplier-invoices/detail', 'add_supplier_panel_org_nr_label')}
									/>
									<TextInput disabled value={suppl_org} />
									<Label
										text={translate(
											'supplier-invoices/detail',
											'add_supplier_panel_pg_bg_account_label'
										)}
									/>
									<TextInput disabled value={suppl_bg} />
									<Label
										text={translate(
											'supplier-invoices/detail',
											'add_supplier_panel_customer_nr_at_supplier_label'
										)}
									/>
									<TextInput
										invalid={invalidFields.includes('customer_nr_at_supplier')}
										value={formData.customer_nr_at_supplier}
										onChange={(e) => {
											if (!isNaN(e.target.value)) {
												setFormData({
													...formData,
													customer_nr_at_supplier: e.target.value
												});
											}
										}}
									/>
								</React.Fragment>

								<Label
									text={translate('supplier-invoices/detail', 'add_supplier_panel_monthly_fee_label')}
								/>
								<TextInputWNote
									invalid={invalidFields.includes('fee_vat')}
									note={translate('supplier-invoices/detail', 'add_supplier_panel_vat_warning_label')}
									value={formData.fee_vat}
									onChange={(e) => {
										if (!isNaN(e.target.value)) {
											setFormData({
												...formData,
												fee_vat: e.target.value
											});
										}
									}}
								/>
								<SingleCheck
									value={formData.auto_release_value_check}
									text={translate(
										'supplier-invoices/detail',
										'add_supplier_panel_always_release_label'
									)}
									onChange={(e) => {
										setFormData({
											...formData,
											auto_release_value_check: e,
											auto_release_value: null
										});
									}}
								/>
								<TextInputWNote
									placeholder=""
									note="kr"
									value={formData.auto_release_value || ''}
									disabled={!formData.auto_release_value_check}
									invalid={invalidFields.includes('auto_release_value')}
									onChange={(e) => {
										if (!isNaN(e.target.value)) {
											setFormData({
												...formData,
												auto_release_value: e.target.value
											});
										}
									}}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<SingleCheck
									value={formData.fee_start_date_calendar}
									text={translate(
										'supplier-invoices/detail',
										'add_supplier_panel_next_invoice_label'
									)}
									onChange={(e) => {
										setFormData({
											...formData,
											fee_start_date_calendar: e
										});
									}}
								/>
								{!formData.fee_start_date_calendar && (
									<Calendar
										value={formData.fee_start_date}
										onChange={(e) => {
											setFormData({
												...formData,
												fee_start_date: e
											});
										}}
									/>
								)}
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								<BorderButton type="submit" text={translate('', 'button_save')} />
							</Grid>
						</Grid>
					</form>
				</Grid>
			</Paper>
		</React.Fragment>
	);
};
SupplierConnection.propTypes = {
	access_token: PropTypes.string.isRequired,
	refresh_token: PropTypes.string.isRequired
};
const mapStateToProps = (store) => ({
	access_token: store.login.access_token,
	refresh_token: store.login.refresh_token,
	supplier: store.supplierInvoices.supplierInvoiceDetail.supplier_data,
	cust_nr_at_supplier: store.supplierInvoices.supplierInvoiceDetail.supplier_invice_data.cust_nr_at_supplier,
	customersSearchList: store.supplierInvoices.customersSearchList
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			submitConnectionDetail
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(memo(SupplierConnection));
