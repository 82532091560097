import React, { memo } from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography, withWidth, Grid } from '@material-ui/core';
import StatusChips from '../../StatusChip';
import toMoney from '../../../misc/serializeToMoney';
import moment from 'moment';
import translate from '../../../misc/applyTranslation';
import {bindActionCreators} from "redux";
import {
  fetchInvoicePDF,
} from "../../../redux/actions/customer/customerDetail";
import {connect} from "react-redux";
import {useConfig} from "../../../context/configContext";

const CustomersList = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const {
    title,
    elevation,
    data, width,
    access_token
  } = props;
  let responsiveTest = (width === 'xs' || width === 'sm');
  const handlePDFAction = (id) => {
    fetchInvoicePDF({access_token: access_token, id: id});

  };

  if (!responsiveTest) {
    return (
      <TableContainer component={Paper} className={classes.container} elevation={elevation}>
        {title && <Typography className={classes.tableTitle}>{title}</Typography>}
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>{translate('customers/detail', 'invoices_table_invoice_number_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'invoices_table_due_day_header')}</TableCell>
              <TableCell aling='right'>{translate('customers/detail', 'invoices_table_amount_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'invoices_table_reminder_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'invoices_table_ocr_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'invoices_table_payed_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'invoices_table_view_header')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{moment.unix(row.inv_due).format('YYYY-MM-DD')}</TableCell>
                <TableCell aling='right'>{toMoney(row.total_sum)}</TableCell>
                <TableCell>{row.reminder}</TableCell>
                <TableCell>{row.ocr}</TableCell>
                <TableCell><StatusChips type={row.inv_paid && row.inv_paid !== '0' ? 'green' : 'red'} label={translate('', row.inv_paid && row.inv_paid !== '0' ? 'string_paid_type_true' : 'string_paid_type_false')} /> </TableCell>
                <TableCell align='center'><img src='/pdf-file.png' alt='PDF' onClick={() => handlePDFAction(row.ocr)} /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return <div style={{ minHeight: 20, marginBottom: 20 }}>

      {data.map((row) => (
        <Grid container key={row.id} className={classes.responsiveContainer}>
          <Grid item xs={4} className='label'>{translate('customers/detail', 'invoices_table_invoice_number_header')}</Grid>
          <Grid item xs={8} className='text'>
            {row.id}
          </Grid>
          <Grid item xs={4} className='label'>{translate('customers/detail', 'invoices_table_due_day_header')}</Grid>
          <Grid item xs={8} className='text'>
            {moment.unix(row.inv_due).format('YYYY-MM-DD')}
          </Grid>
          <Grid item xs={4} className='label'>{translate('customers/detail', 'invoices_table_amount_header')}</Grid>
          <Grid item xs={8} className='text'>
            {row.total_sum}
          </Grid>
          <Grid item xs={4} className='label'>{translate('customers/detail', 'invoices_table_reminder_header')}</Grid>
          <Grid item xs={8} className='text'>
            {row.reminder}
          </Grid>
          <Grid item xs={4} className='label'>{translate('customers/detail', 'invoices_table_ocr_header')}</Grid>
          <Grid item xs={8} className='text'>
            {row.ocr}
          </Grid>
          <Grid item xs={4} className='label'>{translate('customers/detail', 'invoices_table_payed_header')}</Grid>
          <Grid item xs={8} className='text'>
            <StatusChips type={row.inv_paid && row.inv_paid !== '0' ? 'green' : 'red'} label={translate('', row.inv_paid && row.inv_paid !== '0' ? 'string_paid_type_true' : 'string_paid_type_false')} />
          </Grid>
          <Grid item xs={4} className='label'>{translate('customers/detail', 'invoices_table_view_header')}</Grid>
          <Grid item xs={8} className='text'>
            <img src='/pdf-file.png' alt='PDF' onClick={() => handlePDFAction(row.ocr)} />
          </Grid>
        </Grid>
      )
      )}
    </div>
  }
}
CustomersList.propTypes = {
  elevation: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  access_token: PropTypes.string.isRequired,
};

const mapStateToProps = store => ({
  access_token: store.login.access_token,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchInvoicePDF
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(memo(CustomersList));

