import React, { memo } from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import StatusChips from '../../StatusChip';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { useHistory } from 'react-router-dom';
import translate from '../../../misc/applyTranslation';
import {useConfig} from "../../../context/configContext";
const CustomersList = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const history = useHistory();
  const {
    data
  } = props;
  const handleClick = (id, e) => {
    history.push(`customers/${id}`)
  }
  return (
    <TableContainer component={Paper} className={classes.container} >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>{translate('customers', 'table_header_view')}</TableCell>
            <TableCell>{translate('customers', 'table_header_customer_number')}</TableCell>
            <TableCell>{translate('customers', 'table_header_name')}</TableCell>
            <TableCell>{translate('customers', 'table_header_personal_invoice')}</TableCell>
            <TableCell>{translate('customers', 'table_header_address')}</TableCell>
            <TableCell>{translate('customers', 'table_header_postal_code')}</TableCell>
            <TableCell>{translate('customers', 'table_header_city')}</TableCell>
            <TableCell>{translate('customers', 'table_header_country')}</TableCell>
            <TableCell>{translate('customers', 'table_header_status')}</TableCell>
            <TableCell>{translate('customers', 'table_header_service')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id} onClick={handleClick.bind(null, row.id)}>
              <TableCell align='center'><PlayCircleOutlineIcon /></TableCell>
              <TableCell>{row.customer_number}</TableCell>
              <TableCell className={classes.importListItem} >{row.name}</TableCell>
              <TableCell>{row.ssn}</TableCell>
              <TableCell>{row.address1}</TableCell>
              <TableCell>{row.post_code}</TableCell>
              <TableCell>{row.city}</TableCell>
              <TableCell>{row.country}</TableCell>
              <TableCell><StatusChips type={row.status === 'active' ? 'green' : ''} label={row.status} /> </TableCell>
              <TableCell>{row.customer_service_type}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
CustomersList.propTypes = {
  data: PropTypes.array.isRequired,
}

export default memo(CustomersList);