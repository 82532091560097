import React from 'react';
import theme from './style'
import PropTypes from 'prop-types';
import {useConfig} from "../../../context/configContext";


const NewButton = (props) => {
    const config = useConfig();
  const classes = theme(config);
  const { label, onClick, value, } = props;
  return (
    <div className={classes.group}>
      <div className='label'>{value}</div>
      <button type='submit' className={`button ${props.disabled ? 'disabled' : ''}`} onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}>{label}</button>
    </div>
  );
}
NewButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}
export default NewButton;