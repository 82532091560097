import React, { useEffect, useState, Fragment } from 'react';
import { Grid, Paper } from '@material-ui/core';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { BorderSelect, AutoComplete, SingleCheck, TextInput, DatePickerWLabel } from '../../../components/inputs'
import Pagination from '../../../components/Pagination'
import { BorderButton, BatchButton } from '../../../components/buttons'
import { InvoicesList } from '../../../components/tables';
import { fetchSuppliersList } from '../../../redux/actions/suppliers';
import { fetchCustomerTags, } from '../../../redux/actions/settings';
import { fetchFeesList, setInvoicingFilter, clearInvoicingFilter, fetchInvoicingList, createBatch } from '../../../redux/actions/invoices';
import moment from 'moment';
import toValue from '../../../misc/serializeToValue'
import translate from '../../../misc/applyTranslation'
import {useConfig} from "../../../context/configContext";
const UpcomingInvoices = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const [createBatchOpen, setCreateBatchOpen] = useState(false);
  const [firstSearch, setfirstSearch] = useState(false);
  const [batchData, setBatchData] = useState({
    name: '',
    error: false,
    from: moment().format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  });
  const {
    fetchSuppliersList,
    fetchCustomerTags,
    fetchFeesList,
    setInvoicingFilter,
    clearInvoicingFilter,
    fetchInvoicingList,
    createBatch,
    access_token,
    refresh_token,
    fees,
    tags,
    props_suppl,
    suppliersList,
    data,
    page,
    totalPages,
    totalBatches,
    sum_data,
  } = props;
  useEffect(() => {
    fetchSuppliersList({
      access_token,
      refresh_token,
      page: 1,
      limit: 9999,
    });
    fetchCustomerTags({
      access_token,
      refresh_token,
    });
    fetchFeesList({
      access_token: access_token,
      refresh_token: refresh_token,
    });
    // eslint-disable-next-line
  }, [])
  const handleFetchData = (e) => {
    try { e.preventDefault() } catch (error) { }
    setfirstSearch(true);
    page === 1 ? handleFetch() : handlePagination(1)
  }
  function formatNumberWithSpaces(number) {
    // Convert number to string
    let numStr = String(number);

    // Split the string into integer and decimal parts
    let [integerPart, decimalPart] = numStr.split(',');

    // Insert spaces every third non-decimal character in the integer part
    let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    // Combine integer and decimal parts with a comma
    let formattedNumber = formattedIntegerPart + (decimalPart ? ',' + decimalPart : '');

    return formattedNumber;
  }
  const handleFilter = (value, key) => {
    setInvoicingFilter({
      key: key,
      value: value,
    })
  }
  const handleFetch = (e) => {
    try { e.preventDefault() } catch (error) { }
    fetchInvoicingList({
      access_token,
      refresh_token,
      ...props
    })
  }
  const handleBatchChange = (value, key) => {
    let batchDt = JSON.parse(JSON.stringify(batchData))
    batchDt[key] = value;
    setBatchData(batchDt);
  }
  const handleCrateBatch = (e) => {
    try { e.preventDefault() } catch (error) { }
    if (batchData.name.length > 0) {
      createBatch({
        access_token,
        refresh_token,
        ...batchData,
        ...props
      });
      setCreateBatchOpen();
      setBatchData({
        name: '',
        error: false,
        from: moment().format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      })
    } else {
      setBatchData({ ...batchData, error: true })
    }
  }
  const handleResetForm = () => {
    clearInvoicingFilter({
      access_token,
      refresh_token,
    })
  }
  const handlePagination = (value) => {
    setInvoicingFilter({
      key: 'page',
      value: value,
    })
  }
  useEffect(() => {
    if (firstSearch) {
      handleFetch()
    }
    // eslint-disable-next-line
  }, [page])
  return (
    <Grid container className={classes.fixMargins}>
      <Grid item xs={12} sm={6}>
        <Paper className={classes.paperPadding}>
          <div className='paperTitle'>{translate('invoices', 'upcoming_invoice_search')}</div>
          <form onSubmit={handleFetchData}>
            <Grid container className='gridContainer'>
              <Grid item xs={12} sm={6}>
                {suppliersList.length > 0 &&
                  <AutoComplete
                    placeholderNS='invoices'
                    placeholder='invoices_select_supplier'
                    onChange={(e) => handleFilter(e, 'f-supplier')}
                    selected={props['f-supplier']}
                    items={suppliersList.map(item => { return { id: item.id, text: item.suppl_name } })}
                  />
                }
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput placeholder={translate('', 'generic_search_placeholder')} value={props['f-free-text']} onChange={(e) => handleFilter(e.target.value, 'f-free-text')} />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <DatePickerWLabel
                  placeholder={translate('invoices', 'from_date')}
                  value={props['f-from-date']}
                  onChange={(e) => handleFilter(e, 'f-from-date')}
                  maxDate={props['f-to-date'] || moment()}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePickerWLabel
                  placeholder={translate('invoices', 'to_date')}
                  minDate={props['f-from-date'] || moment()}
                  value={props['f-to-date']}
                  onChange={(e) => handleFilter(e, 'f-to-date')} />
              </Grid> */}
              <Grid item xs={6} md={6} lg={3} >
                <SingleCheck value={props['f-unpaid-cust'] === '1' ? true : false} text={translate('invoices', 'upcoming_unpaid')} onChange={(e) => handleFilter(e ? '1' : '0', 'f-unpaid-cust')} />
              </Grid>
              <Grid item xs={6} md={6} lg={3} >
                <SingleCheck value={props['f-no-supl'] === '1' ? true : false} text={translate('invoices', 'upcoming_no_samfaktura')} onChange={(e) => handleFilter(e ? '1' : '0', 'f-no-supl')} />
              </Grid>
              <Grid item xs={6} md={6} lg={3} >
                <SingleCheck value={props['f-incl-unrecived'] === '1' ? true : false} text={translate('invoices', 'upcoming_inc_unreceived')} onChange={(e) => handleFilter(e ? '1' : '0', 'f-incl-unrecived')} />
              </Grid>
              <Grid item xs={6} md={6} lg={3} >
                <SingleCheck value={props['f-incl-negative'] === '1' ? true : false} text={translate('invoices', 'upcoming_inc_negative')} onChange={(e) => handleFilter(e ? '1' : '0', 'f-incl-negative')} />
              </Grid>
              <Grid item xs={12} sm={6}>
                {fees.length > 0 &&
                  <BorderSelect
                    placeholderNS='invoices'
                    placeholder='invoices_select_fee_type'
                    onChange={(e) => handleFilter(e, 'f-fee')}
                    selected={props['f-fee']}
                    items={fees.map(item => { return { id: item.id, text: item.fee_name } })}
                  />
                }
              </Grid>
              <Grid item xs={12} sm={6}>
                {tags.length > 0 &&
                  <BorderSelect
                    placeholderNS='invoices'
                    placeholder='invoices_select_customer_type'
                    onChange={(e) => handleFilter(e, 'f-special')}
                    selected={props['f-special']}
                    items={tags}
                  />
                }
              </Grid>
              <Grid item xs={12} sm={6}>
                {props_suppl.length > 0 &&
                  <BorderSelect
                    placeholderNS='invoices'
                    placeholder='invoices_select_row_filter'
                    onChange={(e) => handleFilter(e, 'f-have-inv')}
                    selected={props['f-have-inv']}
                    items={props_suppl}
                  />
                }
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput placeholder={translate('invoices', 'upcoming_placeholder_suppler_invoice')} value={props['f-supplier-inv']} onChange={(e) => handleFilter(e.target.value, 'f-supplier-inv')} />
              </Grid>
              <Grid item xs={6} sm={3}>
                <BorderButton onClick={handleResetForm} text={translate('', 'button_reset')} />
              </Grid>
              <Grid item xs={6} sm={3}>
                <BorderButton type='submit' text={translate('', 'button_search')} />
              </Grid>
            </Grid>
          </form>
        </Paper >
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper className={classes.paperPadding}>
          <div className='paperTitle'>{translate('invoices', 'upcoming_invoicing')}</div>
          <div className='paperText'>{translate('invoices', 'upcoming_numbers_count')}</div>
        </Paper >

        <BatchButton value={totalBatches} disabled={totalBatches === 0} label={translate('invoices', 'upcoming_create_batch_button')} onClick={() => {
          if (data.length > 0) {
            setCreateBatchOpen(true)
          }
        }} />

        <div id="sum_data-total" data-total={sum_data.sum_data ? sum_data.sum_data.total_sum : ''} className='paperTitle'>{sum_data.sum_data ? 'Total sum incl.vat: ' + formatNumberWithSpaces(toValue(sum_data.sum_data.total_sum)) + " kr" : ''}</div>
        <br/>
        <div className='paperTitle'>{sum_data.sum_data ? 'Antal fakturor: ' + sum_data.sum_data.total_nr_invoices : ''}</div>
        <div className='paperTitle'>{sum_data.sum_data ? 'Antal med avgift: ' + sum_data.sum_data.invoices_with_fees : ''}</div>
        <div className='paperTitle'>{sum_data.sum_data ? 'Antal med elhandel: ' + sum_data.sum_data.el_trade : ''}</div>
        <div className='paperTitle'>{sum_data.sum_data ? 'Antal med samfaktura: ' + sum_data.sum_data.supplier_connection : ''}</div>
        <div id="sum_data-only-fees" data-only-fees={sum_data.sum_data ? sum_data.sum_data.only_fees : ''} className='paperTitle'>{sum_data.sum_data ? 'Antal med BARA avgift: ' + sum_data.sum_data.only_fees : ''}</div>
        
        {createBatchOpen &&
          <Paper className={classes.paperPadding}>
            <div className='paperTitle'>{translate('invoices', 'upcoming_batch_title')}</div>
            <form onSubmit={handleCrateBatch}>
              <Grid container className='gridContainer'>
                <Grid item xs={12}>
                  <TextInput value={batchData.name} invalid={batchData.error} placeholder={translate('invoices', 'upcoming_batch_name_placeholder')} onChange={e => handleBatchChange(e.target.value, 'name')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePickerWLabel
                    value={batchData.from}
                    onChange={e => handleBatchChange(e, 'from')}
                    maxDate={batchData.to || moment()}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePickerWLabel
                    value={batchData.to}
                    onChange={e => handleBatchChange(e, 'to')}
                    minDate={batchData.from || moment()}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BorderButton onClick={e => {
                    setBatchData({
                      name: '',
                      error: false,
                      from: moment().format('YYYY-MM-DD'),
                      to: moment().format('YYYY-MM-DD'),
                    });
                    setCreateBatchOpen(false)
                  }}
                    text={translate('', 'button_cancel')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BorderButton type='submit' onClick={handleCrateBatch} text={translate('', 'button_save')} />
                </Grid>
              </Grid>
            </form>
          </Paper >
        }
      </Grid>
      <div className={classes.tableContainer}>
        {data.length > 0 &&
          <Fragment>
            <InvoicesList data={data} />
            <div style={{ float: 'right' }}>
              <Pagination page={page} totalPages={totalPages} onClick={handlePagination} />
            </div>
          </Fragment>
        }
      </div>
    </Grid>
  );
}
UpcomingInvoices.propTypes = {
  fetchSuppliersList: PropTypes.func.isRequired,
  fetchCustomerTags: PropTypes.func.isRequired,
  fetchFeesList: PropTypes.func.isRequired,
  setInvoicingFilter: PropTypes.func.isRequired,
  clearInvoicingFilter: PropTypes.func.isRequired,
  fetchInvoicingList: PropTypes.func.isRequired,
  createBatch: PropTypes.func.isRequired,

  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
  fees: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  props_suppl: PropTypes.array.isRequired,
  suppliersList: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  fees: store.invoices.fees,
  tags: store.settings.tags,
  props_suppl: store.settings.props_suppl,
  suppliersList: store.suppliers.suppliersList.data,
  data: store.invoices.invoicingList.data,
  totalPages: store.invoices.invoicingList.totalPages,
  totalBatches: store.invoices.invoicingList.totalBatches,
  page: store.invoices.invoicingFilter.page,
  limit: store.invoices.invoicingFilter.limit,
  sum_data: store.invoices.invoicingList,
  'f-status': store.invoices.invoicingFilter['f-status'],
  'f-no-supl': store.invoices.invoicingFilter['f-no-supl'],
  'f-free-text': store.invoices.invoicingFilter['f-free-text'],
  'f-from-date': store.invoices.invoicingFilter['f-from-date'],
  'f-to-date': store.invoices.invoicingFilter['f-to-date'],
  'f-unpaid-cust': store.invoices.invoicingFilter['f-unpaid-cust'],
  'f-have-inv': store.invoices.invoicingFilter['f-have-inv'],
  'f-incl-unrecived': store.invoices.invoicingFilter['f-incl-unrecived'],
  'f-incl-negative': store.invoices.invoicingFilter['f-incl-negative'],
  'f-fee': store.invoices.invoicingFilter['f-fee'],
  'f-special': store.invoices.invoicingFilter['f-special'],
  'f-multi-search': store.invoices.invoicingFilter['f-multi-search'],
  'f-supplier': store.invoices.invoicingFilter['f-supplier'],
  'f-supplier-inv': store.invoices.invoicingFilter['f-supplier-inv'],
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchSuppliersList,
    fetchCustomerTags,
    fetchFeesList,
    setInvoicingFilter,
    clearInvoicingFilter,
    fetchInvoicingList,
    createBatch,

  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UpcomingInvoices);
