import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    buttonGroup: {
      margin: '0px 10px',
    },
    button: {
      fontSize: 13,
      fontWeight: 400,
      textTransform: 'none',
      background: props.color5,
      color: props.color1,
      "-webkit-box-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)",
      "-moz-box-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)",
      boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)",
      paddingTop: 6,
      paddingBottom: 6,
      border: 'none',
      '&:hover': {
        background: props.rgba,
        color: props.color2,
      },
      '& span': {
        height: 20,
      }
    },
    selected: {
      color: props.color6,
      background: props.color3,
      '&:hover': {
        color: props.color1,
        background: props.color3,
      }
    }

  }));
  return styles();
}
export default theme;

