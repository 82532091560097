
import React, { memo, } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Paper } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import translate from '../../misc/applyTranslation';
import {useConfig} from "../../context/configContext";
const CustomerListView = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const { userData } = props;
  const {
    name,
    name2,
  } = userData;
  return (
    <React.Fragment>
      <h1>asd</h1>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  userData: store.login.userData,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(memo(CustomerListView)));
