import React, { memo } from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import translate from '../../../misc/applyTranslation'
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import {useConfig} from "../../../context/configContext";
const CustomersList = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const { data } = props;
  return (
    <TableContainer component={Paper} className={classes.container} >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>{translate('suppliers', 'table_supplier_name')}</TableCell>
            <TableCell>{translate('suppliers', 'table_supplier_org_title')}</TableCell>
            <TableCell>{translate('suppliers', 'table_supplier_address')}</TableCell>
            <TableCell>{translate('suppliers', 'table_supplier_zip_title')}</TableCell>
            <TableCell>{translate('suppliers', 'table_supplier_city_title')}</TableCell>
            <TableCell>{translate('suppliers', 'table_supplier_id')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell className={classes.importListItem} >{row.suppl_name}</TableCell>
              <TableCell>{row.suppl_org}</TableCell>
              <TableCell>{row.suppl_address}</TableCell>
              <TableCell>{row.suppl_post_code}</TableCell>
              <TableCell>{row.suppl_city}</TableCell>
              <TableCell>{row.suppl_external_id}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
CustomersList.propTypes = {
  data: PropTypes.array.isRequired,
}

export default memo(CustomersList);