import React, { memo } from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography, Grid } from '@material-ui/core';
import StatusChips from '../../StatusChip';
import EditIcon from '@material-ui/icons/Edit';
import { connect } from 'react-redux';
import translate from '../../../misc/applyTranslation'
import toMoney from '../../../misc/serializeToMoney'
import {useConfig} from "../../../context/configContext";
const OutboundInvoice = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const {
    onClick,
    noPaper,
    newConnection,
    data,
    name, name2,
  } = props;
    return (
    
    <React.Fragment>
      <TableContainer component={!noPaper ? Paper : 'div'} className={classes.container} >
        {!noPaper &&
          <div className={classes.customerInvoiceListHeader} >
            <Grid container>
              <Grid item xs={12}>
                <Typography className={'forceUppercase'}>{translate('customers/detail', 'edit_connection_table_title').replace('##name##', '')} <span>{name + ' ' + name2}</span></Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{translate('', 'page_title_customer_supplier_connection')}</Typography>
              </Grid>
            </Grid>
          </div>
        }
        <Table className={`${classes.table} ${onClick ? 'click' : ''}`} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>{translate('customers/detail', 'customer_connection_edit_supplier_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'customer_connection_edit_cust_supl_nr_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'customer_connection_edit_fee_header')}</TableCell>
              {!newConnection && <TableCell align='center'>{translate('customers/detail', 'customer_connection_edit_status_header')}</TableCell>}
              {onClick && <TableCell align='center'>{translate('customers/detail', 'customer_connection_edit_edit_header')}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((row) => (
              <TableRow key={row.id} onClick={() => {
                if (onClick) {
                  onClick(`${row.id}?cn=${row.custnr_supplier}`)
                }
              }}>
                <TableCell>{row.suppl_name}</TableCell>
                <TableCell>{row.custnr_supplier}</TableCell>
                <TableCell>{toMoney(row.fee)}</TableCell>
                {!newConnection && <TableCell align='center'>
                  <StatusChips type={row.connection_status === '1' ? 'green' : 'grey'}
                    label={row.connection_status === '1' ? 
                    translate('', 'generic_active')
                    :translate('', 'generic_inactive')} />
                </TableCell>}
                {onClick && <TableCell align='center'><EditIcon /></TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
OutboundInvoice.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.array.isRequired,
}

const mapStateToProps = store => ({
  name: store.customer.customerData.name,
  name2: store.customer.customerData.name2,
});

export default connect(mapStateToProps, null)(memo(OutboundInvoice));