import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    calendar: {
      width: '100%',
      '& .MuiPickersBasePicker-pickerView': {
        maxWidth: '100%',
        width: '100%',
      },
      '& .MuiPickersCalendar-week , .MuiPickersCalendarHeader-daysHeader': {
        justifyContent: 'space-evenly'
      },
      '& .MuiPickersCalendarHeader-daysHeader': {
        background: '#EEE',
        color: '#999',
        paddingTop: 15,
        paddingBottom: 15,
      },
      '& .MuiPickersCalendar-transitionContainer': {
        marginTop: 0,
      },
      '& .MuiPickersDay-day': {
        background: '#f6f6f6',
        color: '#454545',
        borderRadius: 0,
      },
      '& .MuiTouchRipple-rippleVisible , .MuiPickersDay-daySelected': {
        background: '#dad55e',
        borderRadius: '50%',
      },
      '& .MuiPickersCalendarHeader-switchHeader': {
        background: props.color2,
        color: 'white',
        '& button': {
          background: 'transparent',
          '& svg': { fill: 'white' }
        }
      }
    },
  }));
  return styles();
}
export default theme;

