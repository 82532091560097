import React, { memo } from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from '@material-ui/core';
import {useConfig} from "../../context/configContext";

const Pagination = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const {
    onClick, page, totalPages
  } = props;
  const handleClick = (val) => {
    onClick(val)
  }
  return (
    <ButtonGroup size="small" className={classes.buttonGroup}>
      {page - 1 > 0 &&
        <Button
          className={classes.button}
          onClick={handleClick.bind(null, 1)}
        >{'<<'}
        </Button>
      }
      {page - 2 > 0 &&
        <Button
          className={classes.button}
          onClick={handleClick.bind(null, page - 2)}
        >{page - 2}
        </Button>
      }
      {page - 1 > 0 &&
        <Button
          className={classes.button}
          onClick={handleClick.bind(null, page - 1)}
        >{page - 1}
        </Button>
      }
      <Button
        className={`${classes.button} ${classes.selected}`} >{page}
      </Button>
      {page + 1 <= totalPages &&
        <Button
          className={classes.button}
          onClick={handleClick.bind(null, page + 1)}
        >{page + 1}
        </Button>
      }
      {page + 2 < totalPages &&
        <Button
          className={classes.button}
          onClick={handleClick.bind(null, page + 2)}
        >{page + 2}
        </Button>
      }
      {page + 1 < totalPages &&
        <Button
          className={classes.button}
          onClick={handleClick.bind(null, totalPages)}
        >{'>>'}
        </Button>
      }
    </ButtonGroup>
  );
}
Pagination.propTypes = {
  onClick: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
}

export default memo(Pagination);
