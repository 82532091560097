import React from 'react';
import theme from './style'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PropTypes from 'prop-types';
import {useConfig} from "../../../context/configContext";


const NewButton = (props) => {
    const config = useConfig();
  const classes = theme(config);
  const { label, onClick } = props;
  return (
    <div className={classes.group}>
      <div className='label'>{label}</div>
      <div className='button' onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}><PlayCircleOutlineIcon /></div>
    </div>
  );
}
NewButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}
export default NewButton;