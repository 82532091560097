import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    container: {
      border: `1px solid ${props.color1}`,
      backgroundColor: props.rgba,
      borderRadius: 3,
      margin: 10,
      color: props.color1,
      '& .count': {
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
        fontSize: 20,
        fontWeight: 'bold',
      },
      '& .icon': {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20
      },
      '& .title': {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 20,
        fontWeight: 400,
        textTransform: 'uppercase',
        margin: '2px 0px'
      },
      '& .text': {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 12,
        fontWeight: 400,
        textTransform: 'uppercase',
        height: '17px'
      },
      '& .action': {
        height: 48,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '& svg': {
          fontSize: 20
        }
      },
    }
  }));
  return styles();
}
export default theme;

