import React, { memo } from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import translate from '../../../misc/applyTranslation'
import {useConfig} from "../../../context/configContext";
const PendingBatchInvoices = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const {
    title,
    data,
    handleApprove,
    handleDelete
  } = props;

  return (
    <TableContainer component={Paper} className={classes.container} >
      <div className='title'>{title}</div>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>{translate('invoices', 'batch_pending_table_name')}</TableCell>
            <TableCell>{translate('invoices', 'batch_pending_table_in_day')}</TableCell>
            <TableCell>{translate('invoices', 'batch_pending_table_due_day')}</TableCell>
            <TableCell>{translate('invoices', 'batch_pending_table_created_day')}</TableCell>
            <TableCell>{translate('invoices', 'batch_pending_table_number')}</TableCell>
            <TableCell>{translate('invoices', 'batch_pending_table_approve')}</TableCell>
            <TableCell>{translate('invoices', 'batch_pending_table_delete')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.batch_id}>
              <TableCell >{row.name}</TableCell>
              <TableCell>{moment.unix(row.inv_day).format('YYYY-MM-DD')}</TableCell>
              <TableCell>{moment.unix(row.due_day).format('YYYY-MM-DD')}</TableCell>
              <TableCell>{moment.unix(row.create_date).format('YYYY-MM-DD')}</TableCell>
              <TableCell>{row.number_invoices}</TableCell>
              <TableCell><CheckIcon className={classes.CheckIcon} onClick={() => handleApprove(row.batch_id)} /></TableCell>
              <TableCell><ClearIcon className={classes.ClearIcon} onClick={() => handleDelete(row.batch_id)} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
PendingBatchInvoices.propTypes = {
  handleApprove: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
}

export default memo(PendingBatchInvoices);