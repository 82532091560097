import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    invalid: {
      '& input': {
        border: '2px solid #ff524d !important',
      }
    },
    textinput: {
      width: 'calc(100% - 20px)',
      fontWeight: 400,
      fontSize: 14,
      margin: '0px 10px',
      border: 'none',
      '& .Mui-disabled': {
        background: '#eee !important',
        cursor: 'not-allowed'
      },
      '& input': {
        color: '#555',
        fontSize: 14,
        height: 20,
        background: 'white',
        borderRadius: 4,
        padding: '6px 12px',
        '-webkit-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        '-moz-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        border: '1px solid #ddd',
      },
      '& input:focus': {
        border: `1px solid ${props.color3}`,
        '-webkit-box-shadow': `inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px ${props.color3}`,
        boxShadow: `inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px ${props.color3}`,
      },
      '& ::before': {
        borderBottom: 'none !important',
      },
      '& ::after': {
        borderBottom: 'none !important',
      },
      '& ::placeholder': {
        color: '#989898',
        opacity: 1,
      }
    },
    border: {
      '& input': {
        border: `1px solid ${props.color3} !important`,
      }
    },
  }));
  return styles();
}
export default theme;

