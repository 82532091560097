import React, { memo, useEffect } from 'react';

import { Paper, Grid, Typography } from '@material-ui/core';

import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { BorderSelect } from '../../components/inputs';
import { CustomerSupplierInvoices as Table } from '../../components/tables';
import Pagination from '../../components/Pagination';
import { setInvoiceViewId } from '../../redux/actions/customer';
import { setSuppliersInvoicesListFilter, fetchSuppliersInvoicesList } from '../../redux/actions/customer/customerDetail';
import MockDataYears from '../../misc/mockDataYears';
import { useParams } from 'react-router-dom';
import translate from '../../misc/applyTranslation'
import {useConfig} from "../../context/configContext";

import { downloadSupplierInvoicePDF } from '../../redux/actions/supplierInvoices';
const CustomerInvoiceList = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const params = useParams();
  const {
    setSuppliersInvoicesListFilter,
    fetchSuppliersInvoicesList,
    supplier_id,
    year,
    access_token,
    refresh_token,
    suppliers,
    supplierInvoices,
    page,
    limit,
    totalPages,
    name, name2,
    downloadSupplierInvoicePDF
  } = props;

  const handleSelectChange = (value, key) => {
    setSuppliersInvoicesListFilter({
      value: value,
      key: key,
    })
  }
  const handlePagination = (value) => {
    setSuppliersInvoicesListFilter({
      key: 'page',
      value: value,
    })
  }
  useEffect(() => {
    if (supplier_id) {
      setSuppliersInvoicesListFilter({
        key: 'supplier_id',
        value: '',
      })
    }
  }, [])
  useEffect(() => {
    fetchSuppliersInvoicesList({
      customer_id: params.id,
      supplier_id: supplier_id,
      year: year,
      access_token: access_token,
      refresh_token: refresh_token,
      limit: limit,
      page: page,
    })
    // eslint-disable-next-line
  }, [suppliers, year, supplier_id, page])

  const handlePDFDownload = (id, type) => {
    downloadSupplierInvoicePDF({
      access_token,
      refresh_token,
      id: id,
      type
    }).then(res => {
      try {
        let blob = new Blob([res], { type: "application/pdf" });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      } catch (error) {

      }
    })
  }
  return (
    <React.Fragment>
      <Paper elevation={4} className={classes.customerInvoiceListContainer}>
        <div className={classes.customerInvoiceListHeader} >
          <Grid container>
            <Grid item xs={12}>
              <Typography className={'forceUppercase'}>{translate('customers/detail', 'supplier_invoices_table_title_customer')}: <span>{name + '' + name2}</span></Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>{translate('customers/detail', 'supplier_invoices_table_title')}</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <BorderSelect
                    allowAll
                    selected={supplier_id}
                    onChange={(e) => handleSelectChange(e, 'supplier_id')}
                    items={suppliers}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BorderSelect
                    selected={year}
                    onChange={(e) => handleSelectChange(e, 'year')}
                    items={MockDataYears}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{ width: 'calc(100% + 20px)' }}>
          <Table elevation={0} data={supplierInvoices} downloadPDF={handlePDFDownload} />
        </div>
      </Paper >
      <div className={classes.paginationContainer}>
        <Pagination page={page} totalPages={totalPages} onClick={handlePagination} />
      </div>
    </React.Fragment>
  );
}
CustomerInvoiceList.propTypes = {
  setInvoiceViewId: PropTypes.func.isRequired,
  setSuppliersInvoicesListFilter: PropTypes.func.isRequired,
  fetchSuppliersInvoicesList: PropTypes.func.isRequired,
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  supplier_id: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  limit: PropTypes.string.isRequired,
  totalPages: PropTypes.string.isRequired,
  supplierInvoices: PropTypes.array.isRequired,
  suppliers: PropTypes.array.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  year: store.customer.supplierInvoicesListFilter.year,
  supplier_id: store.customer.supplierInvoicesListFilter.supplier_id,
  page: store.customer.supplierInvoicesListFilter.page,
  limit: store.customer.supplierInvoicesListFilter.limit,
  supplierInvoices: store.customer.supplierInvoices.data,
  totalPages: store.customer.supplierInvoices.totalPages,
  suppliers: store.customer.suppliers,
  name: store.customer.customerData.name,
  name2: store.customer.customerData.name2
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    setInvoiceViewId,
    setSuppliersInvoicesListFilter,
    fetchSuppliersInvoicesList,
    downloadSupplierInvoicePDF
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(CustomerInvoiceList));