import React, { memo, useState } from 'react';

import { Paper, Grid } from '@material-ui/core';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import CustomerPanel from '../../components/CustomerPanelEdit';
import DialogBox from '../../components/DialogBox';
import { BorderButton } from '../../components/buttons';
import PNRManagementPanel from '../../components/PNRManagementPanel'
import { useHistory, useParams } from 'react-router-dom';
import { updateCustomer } from '../../redux/actions/customer/customerDetail'
import translate from '../../misc/applyTranslation'
import {useConfig} from "../../context/configContext";

const Overview = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const history = useHistory();
  const params = useParams();
  const {
    updateCustomer,
    access_token,
    refresh_token,
    data,
    facilityDetails,
  } = props;
  const [invalidFields, setInvalidFields] = useState(null);


  const validate = () => {
    let fields = ['ssn', 'name', 'address1', 'post_code', 'city', 'country', 'status', 'delivery', 'customer_type_id']
    let invalid = [];
    fields.forEach(item => {
      if (data[item].length === 0) {
        invalid.push(item)
      }
    })
    setInvalidFields(invalid.length > 0 ? invalid : null);
    return invalid;
  }
  const handleUpdateTrigger = (e) => {
    try { e.preventDefault() } catch (error) { }
    if (validate().length === 0) {
      updateCustomer({
        access_token,
        refresh_token,
        data,
        customer_id: params.id,
      })
    }
  }
  return (
    <React.Fragment>
      <Grid container className={classes.customerContainer}>
        <Grid item xs={8}>
          <form onSubmit={handleUpdateTrigger}>

            <Paper className={classes.paperContainer} elevation={4}>
              <CustomerPanel invalidFields={invalidFields} />
              <div className={classes.saveButton}>
                <BorderButton type='submit' text={translate('', 'button_save')} onClick={handleUpdateTrigger} />
              </div>
            </Paper>
          </form>
        </Grid>
        <Grid item xs={4}>
          {facilityDetails &&
            <Paper className={`${classes.paperContainer} ${classes.facilityContainer}`} elevation={4}>
              <div>
                <p><span>{translate('customers/detail', 'facility_label_props_id')}</span>:{facilityDetails.props_id}</p>
                <p><span>{translate('customers/detail', 'facility_label_customer_id')}</span>:{facilityDetails.customer_id}</p>
              </div>
              <p><span>{translate('customers/detail', 'facility_label_facility')}</span>:{facilityDetails.facility}</p>
              <p><span>{translate('customers/detail', 'facility_label_facility_id')}</span>:{facilityDetails.facility_id}</p>
              <p><span>{translate('customers/detail', 'facility_label_yearly_kw')}</span>:{facilityDetails.yearly_kw}</p>
            </Paper>
          }
          <Paper className={classes.paperContainer} elevation={4}>
            <DialogBox />
          </Paper>
          <Paper className={classes.paperContainer} elevation={4}>
            <BorderButton onClick={e => history.push(`${params.id}/add-invoice-fee`)} text={translate('', 'edit_fees')} />
          </Paper>
          <Paper className={classes.paperContainer} elevation={4}>
            <PNRManagementPanel />
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
Overview.propTypes = {
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
  customerData: PropTypes.object.isRequired,
  customer_types: PropTypes.array.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  data: store.customer.customerData,
  customer_types: store.invoices.customer_types,
  facilityDetails: store.customer.facilityDetails,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    updateCustomer
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(Overview));