import React from 'react';
import theme from './style';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import StatusChips from '../../StatusChip';
import { useHistory } from 'react-router-dom';
import translate from '../../../misc/applyTranslation'
import toValue from '../../../misc/serializeToValue'
import moment from 'moment'
import {useConfig} from "../../../context/configContext";
const OutboundInvoice = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const history = useHistory();
  const {
    data
  } = props;
  const handleClick = (id, e) => {
    history.push(`invoices/add-invoice-fee/${id}`)
  }
  return (
    <React.Fragment>
      <TableContainer component={Paper} className={classes.container} >
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>{translate('invoices', 'invoices_table_customer_header')}</TableCell>
              <TableCell>{translate('invoices', 'invoices_table_name_header')}</TableCell>
              <TableCell>{translate('invoices', 'invoices_table_pnr_header')}</TableCell>
              <TableCell>{translate('invoices', 'invoices_table_del_header')}</TableCell>
              <TableCell>{translate('invoices', 'invoices_table_inr_header')}</TableCell>
              <TableCell>{translate('invoices', 'invoices_table_vat_header')}</TableCell>
              <TableCell>{translate('invoices', 'invoices_table_amount_header')}</TableCell>
              <TableCell>{translate('invoices', 'invoices_table_fee_header')}</TableCell>
              <TableCell>{translate('invoices', 'invoices_table_fee_vat_header')}</TableCell>
              <TableCell>{translate('invoices', 'invoices_table_inv_header')}</TableCell>
              <TableCell>{translate('invoices', 'invoices_table_indr_header')}</TableCell>
              <TableCell>{translate('invoices', 'invoices_table_paid_header')}</TableCell>
              <TableCell>{translate('invoices', 'invoices_table_state_header')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, dataind) => {
              let ele = [];
              row.rows.forEach((inv, ind) => {
                ele.push(<TableRow key={inv.id + ind} className={ind === 0 ? 'firstRow' : 'row'} >
                  {ind === 0 &&
                    <React.Fragment>
                      <TableCell>{row.customer_number}</TableCell>
                      <TableCell>{row.customer_name}</TableCell>
                      <TableCell>{row.personal_number}</TableCell>
                      <TableCell>{row.delv}</TableCell>
                    </React.Fragment>
                  }
                  {ind !== 0 &&
                    <TableCell colSpan={4} />
                  }
                  <TableCell>{inv.inv_row_text}</TableCell>
                  <TableCell>{toValue(inv.inv_row_vat)}</TableCell>
                  <TableCell>{toValue(inv.inv_row_amount)}</TableCell>
                  <TableCell>{toValue(inv.inv_fee)}</TableCell>
                  <TableCell>{toValue(inv.inv_fee_vat)}</TableCell>
                  {ind === 0 &&
                    <React.Fragment>
                      <TableCell>{row.inv}</TableCell>
                      <TableCell>{row.inv_due ? moment.unix(row.inv_due).format('YYYY-MM-DD') : ''}</TableCell>
                      <TableCell>{row.paid}</TableCell>
                      <TableCell colSpan={2} />
                    </React.Fragment>
                  }
                  {ind !== 0 &&
                    <TableCell colSpan={5} />
                  }
                </TableRow>)
              })
              ele.push(
                <TableRow key={dataind} className='totalRow'>
                  <TableCell colSpan={4} />
                  <TableCell colSpan={3} />
                  <TableCell colSpan={2} >{`SUM ${toValue(row.rows_total)}`}</TableCell>
                  <TableCell colSpan={3} />
                  <TableCell>{row.status && <StatusChips type={'green'} label={'Open'} />}</TableCell>
                  <TableCell><StatusChips type={'border_blue'} label={'EDIT_FEES'} onClick={() => handleClick(row.customer_id)} /></TableCell>
                </TableRow>
              )
              return ele;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment >
  );
}

export default OutboundInvoice;