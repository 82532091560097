import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    fileInputContainer: {
      marginBottom: 15,
      padding: 10,
      '& .title': {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#989898',
        marginBottom: 10,
      },
      '& .text': {
        fontSize: 13,
        fontWeight: 400,
        color: '#989898',
        marginBottom: 10,
      },
      '& .fileInput': {
        marginBottom: 10,
        width: 'calc(100% - 12px)',
        color: '#555',
        fontSize: 14,
        // height: 20,
        background: 'white',
        borderRadius: 4,
        padding: 6,
        '-webkit-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        '-moz-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        border: '1px solid #ddd',
        '& :focus': {
          border: `1px solid ${props.color2}`,
          '-webkit-box-shadow': `inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px ${props.color2}`,
          boxShadow: `inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px ${props.color2}`,
        },
      },
      '& .buttonContainer': {
        width: 'calc(100% + 20px)',
        marginLeft: -10,
        display: 'flex',
        justifyContent: 'flex-end',
        '& button': {
          maxWidth: 150
        }
      },
    },
    fileListContainer: {
      marginBottom: 15,
      padding: 10,
      '& .title': {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#989898',
        marginBottom: 10,
      },
      '& .selectContainer': {
        width: 'calc(100% + 20px)',
        marginLeft: -10,
        '& .MuiFormControl-root ': {
          maxWidth: 300
        }
      },


    },
    fileDetailContainer: {
      padding: 10,
      marginTop: 15,
      widht: '100%',
      maxHeight: 500,
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
      border: '1px solid #ddd',
      borderRadius: 4,
      overflowY: 'auto',

      '& .upload_log_success': {
        fontSize: 13,
        color: 'green',
        margin: 0,
      },
      '& .upload_log_error': {
        fontSize: 13,
        color: 'red',
        fontWeight: 'bold',
        margin: 0,

      },
      '& .upload_log_log': {
        color: '#989898',
        fontSize: 13,
        margin: 0,

      },
      '& .upload_log_summary': {
        borderRadius: 4,
        border: '1px solid #ccc',
        backgroundColor: '#f5f5f5',
        color: '#333',
        padding: 10,
        display: 'block',
        margin: 0,
      },
    }
  }));
  return styles();
}
export default theme;

