import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    button: {
      width: 'calc(100% - 20px)',
      borderRadius: 4,
      borderColor: props.color2,
      color: props.color1,
      borderWidth: 2,
      fontWeight: 400,
      fontSize: 14,
      textTransform: 'none',
      height: 34,
      '& svg': {
        fontSize: '16px !important'
      },
      margin: '0px 10px'
    }
  }));
  return styles();
}
export default theme;

