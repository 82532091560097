
import React, { memo, } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { BorderButton, } from '../../components/buttons'
import { TextInput, } from '../../components/inputs'
import { Grid, Paper } from '@material-ui/core';
import { useHistory, useParams, withRouter } from "react-router-dom";
import { setFormData } from '../../redux/actions/login';
import {useConfig} from "../../context/configContext";

const AuthBankId_TFA = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const history = useHistory();
  const params = useParams();
  const { setFormData, code } = props;
  const handleForm = (key, value) => {
    setFormData({ key: key, value: value })
  }
  return (
    <form onSubmit={(e) => e.preventDefault()} className={classes.formContainer}>
      <Grid container className={'gridContainer'}>
        <Grid item xs={11} sm={9} md={7} lg={5} className={'gridItemContainer'}>
          {/* <Paper className={'logoContainer'}>
            <img src={settings.logoAdmin} alt='logo'/>
          </Paper> */}
          <div className={'inputGroup'}>
            <TextInput border placeholder='Code' value={code} onChange={(e) => handleForm('code', e.target.value)} />
            <BorderButton text={'Validate'} onClick={() => console.log('a')} />
          </div>
        </Grid>
        <Grid item xs={12} className={'navigationContainer'}>
          <div className={'navigationURL'} onClick={() => history.push(`/${params.lang}/login`)}>
            PASSWORD_LOGIN
          </div>
          <div className={'navigationURL'} onClick={() => history.push(`/${params.lang}/forgot-password`)}>
            FORGOT_YOUR_PASSWORD
          </div>
        </Grid>
      </Grid>
    </form>
  )
}
AuthBankId_TFA.propTypes = {
  setFormData: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
}
const mapStateToProps = store => ({
  code: store.login.code,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    setFormData,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(memo(AuthBankId_TFA)));