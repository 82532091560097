import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    breadcrumbsContainer: {
      display: 'flex',
      background: 'white',
      margin: '-15px -15px 20px -15px',
      padding: 15,
      borderTop: '1px solid rgba(0,0,0,0.14)',
      minHeight: 35,
      alignItems: 'center'
    },
    breadcrumbsTitle: {
      color: '#989898',
      fontSize: 20,
      fontWeight: 300,
      marginRight: 20,
    },
    breadcrumbsItem: {
      color: props.color2,
      fontWeight: 300,
      fontSize: 13,
      textDecoration: 'underline'
    }
  }));
  return styles();
}
export default theme;

