import React, { memo } from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from '@material-ui/core';
import {useConfig} from "../../context/configContext";

const Tabs = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const {
    onClick, selected, items
  } = props;
  const handleClick = (id, e) => {
    onClick(id)
  }
  return (
    <ButtonGroup size="small" className={classes.buttonGroup}>
      {items.map(item =>
        <Button
          className={`${classes.button} ${items.length === 4 ? classes.button25 : classes.button20} ${item.id === selected ? classes.selected : ''}`}
          key={item.id}
          onClick={handleClick.bind(null, item.id)}
        >{item.text}
        </Button>
      )}
    </ButtonGroup>
  );
}
Tabs.propTypes = {
  onClick: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
}

export default memo(Tabs);