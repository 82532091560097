
import React, { memo, } from 'react';
import theme from './style';
import { Paper, Grid, Typography } from '@material-ui/core';
import translate from '../../misc/applyTranslation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {useConfig} from "../../context/configContext";
const ContactsView = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const { userData } = props;
  const {
    address1,
    address2,
    name,
    name2,
    post_code,
    city,
    country,
    email1,
    email2,
    tel1,
    tel2,
  } = userData;

  return (
    <React.Fragment>
      <Paper className={classes.container}>
        <div className='title'>{translate('my-info', 'my_info_panel_title')}</div>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={3} className='label'>{translate('my-info', 'my_info_panel_name_label')}</Grid>
          <Grid item xs={12} sm={6} md={8} lg={9} className='text'>{name} {name2}</Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} className='label'>{translate('my-info', 'my_info_panel_address_label')}</Grid>
          <Grid item xs={12} sm={6} md={8} lg={9} className='text'>
            <p>{address1 + address2}</p>
            <p>{post_code} {city}</p>
            <p>{country}</p>
          </Grid>
        </Grid>
        <div className='title'>{translate('my-info', 'contacts_panel_title')}</div>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={3} className='label'>{translate('my-info', 'contacts_panel_email1_label')}</Grid>
          <Grid item xs={12} sm={6} md={8} lg={9} className='text'>{email1}</Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} className='label'>{translate('my-info', 'contacts_panel_email2_label')}</Grid>
          <Grid item xs={12} sm={6} md={8} lg={9} className='text'>{email2}</Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} className='label'>{translate('my-info', 'contacts_panel_mobile1_label')}</Grid>
          <Grid item xs={12} sm={6} md={8} lg={9} className='text'>{tel1}</Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} className='label'>{translate('my-info', 'contacts_panel_mobile2_label')}</Grid>
          <Grid item xs={12} sm={6} md={8} lg={9} className='text'>{tel2}</Grid>
        </Grid>
        <div style={{ maxWidth: 400 }}>
          <Typography style={{ color: config.color2 }}>{translate('my-info', 'panel_change_warning')}</Typography>
        </div>
      </Paper>
    </React.Fragment>
  )
}

ContactsView.propTypes = {
}
const mapStateToProps = store => ({
  userData: store.login.userData,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(ContactsView));