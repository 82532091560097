import React from 'react';
import theme from './style'
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {useConfig} from "../../../context/configContext";


const BorderButton = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const { text, onClick, active, warning,success, type } = props;
  return (
    <Button
      onClick={(e) => { if (onClick && !props.disabled) onClick(e) }}
      type={type || 'button'}
      className={`${classes.button} ${success ? classes.success : ''} ${warning ? classes.warning : ''} ${active ? classes.active : ''} ${props.disabled ? classes.disabled : ''} `}
      variant="outlined" 
    >{text}</Button>
  );
}
BorderButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool,
  warning: PropTypes.bool,
  type: PropTypes.string,
}
export default BorderButton;