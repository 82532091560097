import React, { useState, memo } from 'react';
import { TextField, FormControl } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import theme from './style';
import translate from '../../../misc/applyTranslation'
import {useConfig} from "../../../context/configContext";
const AutoCompleteComponent = (props) => {
  const { items, selected, onChange, allowAll, placeholder, placeholderNS } = props;
  const [inputValue, setInputValue] = useState('');
  const config = useConfig();
  const classes = theme(config);
  return (
    <FormControl variant="outlined" className={classes.selectGroup}>
      <Autocomplete
        className='select'
        value={selected ? items.find(item => String(item.id) === String(selected)) : undefined}
        onChange={(event, newValue) => {
          if (onChange) {
            onChange(newValue ? newValue.id : null);
          }
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => option.text}
        options={items}
        renderInput={(params) => <TextField placeholder={translate(placeholderNS || '', placeholder || (allowAll ? 'generic_select_all' : 'generic_select'))} {...params} />}
      />
    </FormControl>
  );
}
AutoCompleteComponent.propTypes = {
  items: PropTypes.array.isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}
export default memo(AutoCompleteComponent);