
import React, { memo, useEffect, useState, } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BorderButton } from '../../components/buttons'
import CustomerNew from '../../components/CustomerPanelNew'
import { Paper } from '@material-ui/core';
import translate from '../../misc/applyTranslation';
import { updateCustomer, clearCustomerData } from '../../redux/actions/customer/customerDetail'
import {useConfig} from "../../context/configContext";

const CustomerListView = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const {
    updateCustomer,
    access_token,
    refresh_token,
    data,
    clearCustomerData,
  } = props;
  // eslint-disable-next-line
  useEffect(() => { clearCustomerData(); }, [])
  const [invalidFields, setInvalidFields] = useState(null);

  const validate = () => {
    let fields = ['ssn', 'name', 'address1', 'post_code', 'city', 'country', 'status', 'delivery', 'customer_type_id']
    let invalid = [];
    fields.forEach(item => {
      if (item === 'ssn' && data[item].length !== 12) {
        if(data[item].length !== 10){
          invalid.push(item)
        }
      } else if (data[item].length === 0) {
        invalid.push(item)
      }
    })
    setInvalidFields(invalid.length > 0 ? invalid : null);
    return invalid;
  }
  const handleNewCustomer = (e) => {
    try { e.preventDefault() } catch (error) { }
    if (validate().length === 0) {
      updateCustomer({
        access_token,
        refresh_token,
        data,
        verb: 'post'
      })
    }
  }
  return (
    <Paper style={{ padding: 10 }}>
      <div className={classes.title}>
        {translate('', 'menu_add_customers')}
      </div>
      <CustomerNew invalidFields={invalidFields} />
      <div className={classes.saveButton}>
        <BorderButton onClick={handleNewCustomer} text={translate('', 'button_save')} />
      </div>
    </Paper>
  )
}
CustomerListView.propTypes = {
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  data: store.customer.customerData,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateCustomer, clearCustomerData }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(CustomerListView));