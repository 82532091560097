import React, { memo, useEffect } from 'react';

import { Paper } from '@material-ui/core';
import Tabs from '../../components/Tabs'
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Overview from './overview';
import CustomerInvoiceList from './customerInvoicesList';
import CustomerInvoiceDetail from './customerInvoicesDetail';
import SupplierInvoices from './SuplierInvoices';
import EditSupplierConnection from './editSupplierConnection';
import EditCustomer from './editCustomer';
import { setSelectedTab } from '../../redux/actions/customer';
import { fetchSuppliersList, fetchCustomerDetail } from '../../redux/actions/customer/customerDetail';
import { setInvoiceViewId } from "../../redux/actions/customer";
import { fetchCustomerTypes } from '../../redux/actions/invoices';
import { fetchCustomerTags } from '../../redux/actions/settings';
import { useParams } from 'react-router-dom';
import translate from '../../misc/applyTranslation'
import {useConfig} from "../../context/configContext";

const CustomerDetail = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const {
    fetchCustomerDetail,
    fetchSuppliersList,
    fetchCustomerTypes,
    fetchCustomerTags,
    setSelectedTab,
    selectedTab,
    selectedInvoiceViewId,
    setInvoiceViewId,
    access_token,
    refresh_token,
  } = props;
  const params = useParams();
  useEffect(() => {
    fetchCustomerDetail({
      access_token: access_token,
      refresh_token: refresh_token,
      customer_id: params.id,
    })
    fetchCustomerTypes({
      access_token: access_token,
      refresh_token: refresh_token,
    })
    fetchCustomerTags({
      access_token,
      refresh_token,
    })
    fetchSuppliersList({
      access_token: access_token,
      refresh_token: refresh_token,
      customer_id: params.id,
    })
    setSelectedTab(0);
    setInvoiceViewId('null');
    // eslint-disable-next-line
  }, [])

  return (
    <Paper className={classes.paperPadding}>
      <Tabs
        onClick={(e) => setSelectedTab(e)}
        selected={selectedTab}
        items={
          [
            {
              text: translate('customers/detail', 'tab_overview'),
              id: 0
            },
            {
              text: translate('customers/detail', 'tab_invoices'),
              id: 1
            },
            {
              text: translate('customers/detail', 'tab_supplier_invoices'),
              id: 2
            },
            {
              text: translate('customers/detail', 'tab_edit_customer'),
              id: 3
            },
            {
              text: translate('customers/detail', 'tab_edit_supplier_connection'),
              id: 4
            },
          ]
        } />
      {selectedTab === 0 &&
        <Overview />
      }
      {selectedTab === 1 && String(selectedInvoiceViewId) === 'null' &&
        <CustomerInvoiceList />
      }
      {selectedTab === 1 && String(selectedInvoiceViewId) !== 'null' &&
        <CustomerInvoiceDetail />
      }
      {selectedTab === 2 &&
        <SupplierInvoices />
      }
      {selectedTab === 3 &&
        <EditCustomer />
      }
      {selectedTab === 4 &&
        <EditSupplierConnection />
      }
    </Paper >
  );
}
CustomerDetail.propTypes = {
  selectedTab: PropTypes.number.isRequired,
  fetchSuppliersList: PropTypes.func.isRequired,
  fetchCustomerDetail: PropTypes.func.isRequired,
  fetchCustomerTypes: PropTypes.func.isRequired,
  fetchCustomerTags: PropTypes.func.isRequired,
  selectedInvoiceViewId: PropTypes.string,
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired
}
const mapStateToProps = store => ({
  selectedTab: store.customer.selectedTab,
  selectedInvoiceViewId: store.customer.selectedInvoiceViewId,
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    setInvoiceViewId,
    setSelectedTab,
    fetchSuppliersList,
    fetchCustomerDetail,
    fetchCustomerTypes,
    fetchCustomerTags,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(CustomerDetail));
