import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    container: {
      padding: 20,
      '& .titleHome': {
        color: '#989898',
        fontWeight: 300,
        fontSize: 30,
        opacity: 1,
        marginBottom: 10,
        '& span': {
        fontWeight: 400,
          opacity: 0.8,
          color: props.color2,
        }
      },
      '& .logo': {
        minHeight: 221,
        maxWidth: 700,
        margin: '0 auto',
        backgroundSize: '100%',
        background: `transparent url("${props.logoAuthPath}") no-repeat scroll center center`,
      },
    }
  }));
  return styles();
}
export default theme;

