import React, { Fragment, memo } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import translate from '../../../misc/applyTranslation';
import toMoney from '../../../misc/serializeToMoney';
import moment from 'moment';
import {useConfig} from "../../../context/configContext";

const InvoiceDetails = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const {
    supplier_invice_data,
  } = props;
  if (supplier_invice_data.length === 0) {
    return false;
  } else {
    return (
      <Fragment>
        <div className={classes.titleGray}><img src='/pdf-file.png' alt='PDF' className={classes.imgPDF} />{translate('supplier-invoices/detail', 'customer_panel_invoice_nr_title')} {supplier_invice_data.sup_inv_nr}</div>
        <div className={classes.columnContainer}>
          <div className='columnlabel'>
            <div className={classes.text}>{translate('supplier-invoices/detail', 'customer_panel_stel_invoice_day_label')}</div>
            <div className={classes.text}>{translate('supplier-invoices/detail', 'customer_panel_stel_due_day')}</div>
            <div className={classes.text}>{translate('supplier-invoices/detail', 'customer_panel_ocr_label')}</div>
            <div className={classes.text}>{translate('supplier-invoices/detail', 'customer_panel_amount_label')}</div>
            <div className={classes.text}>{translate('supplier-invoices/detail', 'customer_panel_vat_label')}</div>
          </div>
          <div className='columnvalue'>
            <div className={classes.text}>{moment.unix(supplier_invice_data.sup_inv_day).format('YYYY-MM-DD')}</div>
            <div className={classes.text}>{moment.unix(supplier_invice_data.sup_due_day).format('YYYY-MM-DD')}</div>
            <div className={classes.text}>{supplier_invice_data.sup_ocr}</div>
            <div className={classes.text}>{toMoney(supplier_invice_data.amount_ex_vat)}</div>
            <div className={classes.text}>{toMoney(supplier_invice_data.amount_vat)}</div>
          </div>
        </div>
      </Fragment >
    );
  }

}
InvoiceDetails.propTypes = {
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  supplier_invice_data: store.supplierInvoices.supplierInvoiceDetail.supplier_invice_data,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(InvoiceDetails));