import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    root: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .container': {
        width: '100%',
        '& .logo': {
          height: 175,
          maxWidth: 400,
          margin: '0 auto',
          backgroundSize: '100%',
          background: `transparent url("${props.logoAuthPath}") no-repeat scroll center center`,
        },
        '& .body': {

        }
      },

    }
  }));
  return styles();
}
export default theme;

