import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    selectGroup: {
      display: 'flex',
      width: 'calc(100% - 20px)',
      margin: '0px 10px',
      '& .MuiOutlinedInput-root': {
        color: props.color1,
        height: 30,


      },
      '& ::before , ::after': {
        border: 'none !important',
      },
      '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
        paddingRight: '46px !important',
      },
      '& ::placeholder': {
        color: props.color1,
        opacity: 1,
      },
      '& .MuiAutocomplete-input': {
        color: props.color1,
        paddingLeft: '14px !important',

      },
      '& .select': {
        height: 32,
        border: `2px solid ${props.color2} !important`,
        borderRadius: 4,
      },
      '& svg': {
        fill: props.color1,
        fontSize: 20,
        marginTop: 0
      }

    },
    selectOption: {
      fontSize: 13,
      color: '#989898'
    },
    invalid: {
      '& ::placeholder': {
        color: '#ff524d',
      },
      '& svg': {
        fill: '#ff524d',
      },
      '& .select': {
        border: '2px solid #ff524d !important',
      },
    }
  }));
  return styles();
}
export default theme;

