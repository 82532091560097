import React, { memo, useState } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import CreateIcon from '@material-ui/icons/Create';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { TextInput, BorderSelect } from '../../inputs';
import { NewButton, BorderButton, } from '../../buttons';
import { tempUpdateFees, setLoadingInvoices, saveFeesChanges, addNewFee, deleteFee } from '../../../redux/actions/invoices';
import translate from '../../../misc/applyTranslation';
import toMoney from '../../../misc/serializeToMoney';
import ConfirmationModal from '../../../components/confirmationWindow';
import {useConfig} from "../../../context/configContext";
const FeesTable = (props) => {
  const config = useConfig();
  const classes = theme(config);
  // const [confirmationWindow, setConfirmationWindow] = useState(false);
  const [holdTempVals, setHoldTempVals] = useState();
  const {
    data,
    types,
    updateList,
    tempUpdateFees,
    access_token,
    refresh_token,
    addNewFee,
    deleteFee,
    setLoadingInvoices,
    saveFeesChanges
  } = props;
  const handleEdit = (id, key, value) => {
    tempUpdateFees({
      id: id,
      key: key,
      value: value,
    })
  }
  const handleDelete = async () => {
    const { id, ind } = holdTempVals;
    await deleteFee({
      id: id,
      ind: ind,
      access_token: access_token,
      refresh_token: refresh_token,
    })
    handleClose();
    updateList();
  }
  const prepareSaveFeesChanges = async () => {
    let arr = data.filter(item => item.edit_mode || !item.id);
    await arr.forEach(async item => {
      var formdata = new FormData();
      if (item.id) {
        formdata.append("fee_id", item.id);
      }
      formdata.append("fee_name", item.fee_name || 'New');
      formdata.append("fee_amount", item.fee_amount || '0');
      formdata.append("fee_vat", item.fee_vat || '0');
      formdata.append("fee_segment", item.fee_segment || '3');
      formdata.append("accountnumber", item.accountnumber || '0');
      formdata.append("negative", item.negative || '0');
      await saveFeesChanges({
        verb: item.id ? 'patch' : 'post',
        access_token: access_token,
        refresh_token: refresh_token,
        body: formdata
      })
    })
    setLoadingInvoices(true);

    setTimeout(() => {
      updateList();
    }, 1000);

  }
  const returnTypeName = (row) => {
    try {
      return types.find(item => item.id === row.fee_segment).text
    } catch (error) {
      return ''
    }
  }
  const handleClose = () => {
    setHoldTempVals()
  }
  const handleOpen = (id, ind) => {
    setHoldTempVals({
      id: id,
      ind: ind,
    })
  }
  return (
    <React.Fragment>
      <ConfirmationModal open={!!holdTempVals} onAbort={handleClose} onAccept={handleDelete} />
      <TableContainer component={Paper} className={classes.container} >
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>{translate('invoices', 'fees_table_header_nane')}</TableCell>
              <TableCell>{translate('invoices', 'fees_table_header_vat')}</TableCell>
              <TableCell>{translate('invoices', 'fees_table_account_number')}</TableCell>
              <TableCell>{translate('invoices', 'fees_table_customer')}</TableCell>
              <TableCell>{translate('invoices', 'fees_table_fee_inc_vat')}</TableCell>
              <TableCell>{translate('invoices', 'fees_table_fee_exc_vat')}</TableCell>
              <TableCell>{translate('invoices', 'fees_table_negative_fee')}</TableCell>
              <TableCell>{translate('invoices', 'fees_table_edit')}</TableCell>
              <TableCell>{translate('invoices', 'fees_table_delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, ind) => {
              if (row.edit_mode || !row.id) {
                return <TableRow key={row.id || `new-${ind}`} className='rowEdit' >
                  <TableCell>
                    <TextInput value={row.fee_name} onChange={(e) => handleEdit(ind, 'fee_name', e.target.value)} />
                  </TableCell>
                  <TableCell>
                    <TextInput value={row.fee_vat} onChange={(e) => handleEdit(ind, 'fee_vat', e.target.value)} />
                  </TableCell>
                  <TableCell>
                    <TextInput value={row.accountnumber} onChange={(e) => handleEdit(ind, 'accountnumber', e.target.value)} />
                  </TableCell>
                  <TableCell>
                    <BorderSelect
                      selected={row.fee_segment}
                      items={types}
                      onChange={(e) => handleEdit(ind, 'fee_segment', e)}
                    />
                  </TableCell>
                  <TableCell style={{ paddingLeft: 16 }}>
                    {toMoney(Number(row.fee_amount) * ((Number(row.fee_vat) / 100) + 1))}
                  </TableCell>
                  <TableCell>
                    <TextInput value={row.fee_amount / 100} onChange={(e) => handleEdit(ind, 'fee_amount', Number(e.target.value) * 100)} />
                  </TableCell>
                  <TableCell className='icon pointer'
                    onClick={(e) => handleEdit(ind, 'negative', row.negative === '1' ? '0' : '1')}
                  >{row.negative === '1' ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</TableCell>
                  <TableCell className='icon pointer'></TableCell>
                  <TableCell className='icon pointer'>
                    {row.edit_mode && !row.not_deletable &&
                      <ClearIcon style={{ fill: 'red' }} onClick={() => { if (row.id) { handleOpen(row.id, ind) } }} />
                    }
                  </TableCell>
                </TableRow>
              } else {
                return <TableRow key={row.id || `new-${ind}`} className='row' >
                  <TableCell>{row.fee_name}</TableCell>
                  <TableCell>{row.fee_vat}</TableCell>
                  <TableCell>{row.accountnumber}</TableCell>
                  <TableCell>{returnTypeName(row)}</TableCell>
                  <TableCell>{toMoney(Number(row.fee_amount) * ((Number(row.fee_vat) / 100) + 1))}</TableCell>
                  <TableCell>{toMoney(row.fee_amount)}</TableCell>
                  <TableCell className='icon'>{row.negative === '1' ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</TableCell>
                  <TableCell className='icon pointer' onClick={() => handleEdit(ind, 'edit_mode', true)}><CreateIcon /></TableCell>
                  <TableCell className='icon pointer' >
                    {!row.not_deletable &&
                      <ClearIcon style={{ fill: 'red' }} onClick={() => { if (row.id) { handleOpen(row.id, ind) } }} />
                    }</TableCell>
                </TableRow>
              }
            }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.buttonContainer}>
        <NewButton onClick={addNewFee} text={translate('invoices', 'fees_add_new_fee')} />
        <BorderButton onClick={updateList} text={translate('', 'button_cancel')} />
        <BorderButton onClick={prepareSaveFeesChanges} text={translate('', 'button_save')} />
      </div>
    </React.Fragment >
  );
}
FeesTable.propTypes = {
  data: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
  updateList: PropTypes.func.isRequired,
  tempUpdateFees: PropTypes.func.isRequired,
  addNewFee: PropTypes.func.isRequired,
  deleteFee: PropTypes.func.isRequired,
}
const mapStateToProps = store => ({
  types: store.invoices.customer_types,
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    tempUpdateFees,
    addNewFee,
    deleteFee,
    setLoadingInvoices,
    saveFeesChanges,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(FeesTable));