import React, {memo, useEffect} from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';
import StatusChips from '../../StatusChip';
import { useHistory } from 'react-router-dom';
import toMoney from '../../../misc/serializeToMoney';
import translate from '../../../misc/applyTranslation';
import {resetCustomerInvoiceDetail} from "../../../redux/actions/customer/customerDetail";
import {bindActionCreators} from "redux";
import {useConfig} from "../../../context/configContext";
const InboundInvoice = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const history = useHistory();
  const {
    invoice,
    data,
    title,
    downloadPDF,
    resetCustomerInvoiceDetail
  } = props;
  let sum = 0;
  const handlePDF = (e, id) => {
    e.stopPropagation()
    if (downloadPDF) {
      downloadPDF(id, 'pdfSupplierInvoice')
    }
  }

  //   const handlePDFDownload = (id) => {
  //   downloadSupplierInvoicePDF({
  //     access_token,
  //     refresh_token,
  //     id: id,
  //     type: 'pdfSupplierInvoice'
  //   }).then(res => {
  //     try {
  //       var url = window.URL.createObjectURL(res);
  //       var a = document.createElement('a');
  //       a.href = url;
  //       a.target = '_blank';
  //       document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  //       a.click();
  //       a.remove();
  //     } catch (error) {

  //     }
  //   })
  // }

  useEffect(() => {
    return () => {
      resetCustomerInvoiceDetail();
    };
  }, []);

  return (
    <TableContainer component={Paper} className={classes.container} >
      {title && <Typography className={classes.tableTitle}>{title}</Typography>}
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>{translate('customers/detail', 'invoices_table_detail_inbound_supplier_header')}</TableCell>
            <TableCell>{translate('customers/detail', 'invoices_table_detail_inbound_type_header')}</TableCell>
            <TableCell>{translate('customers/detail', 'invoices_table_detail_inbound_invoice_number_header')}</TableCell>
            <TableCell>{translate('customers/detail', 'invoices_table_detail_inbound_invoice_day_header')}</TableCell>
            <TableCell>{translate('customers/detail', 'invoices_table_detail_inbound_due_day_header')}</TableCell>
            <TableCell>{translate('customers/detail', 'invoices_table_detail_inbound_amount_header')}</TableCell>
            <TableCell>{translate('customers/detail', 'invoices_table_detail_inbound_reminder_header')}</TableCell>
            <TableCell>{translate('customers/detail', 'invoices_table_detail_inbound_inv_ocr_header')}</TableCell>
            <TableCell>{translate('customers/detail', 'invoices_table_detail_inbound_payed_header')}</TableCell>
            <TableCell>{translate('customers/detail', 'invoices_table_detail_inbound_view_header')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            sum += Number(row.inv_row_amount) + Number(row.inv_row_vat);

            return (
              <TableRow key={row.supplier_invoice_id}>
                <TableCell>{row.inv_row_text}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.invoice_id}</TableCell>
                <TableCell>{row.invoiceDate}</TableCell>
                <TableCell>{row.dueDate}</TableCell>
                <TableCell>{toMoney(Number(row.inv_row_amount) + Number(row.inv_row_vat))}</TableCell>
                <TableCell>{row.reminder}</TableCell>
                <TableCell>{row.inv_orc}</TableCell>
                <TableCell><StatusChips type={row.payed === 'payed' ? 'green' : row.payed === 'not_payed' ? 'grey' : ''} label={row.payed} /> </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align='center'>
                  {row.supplier_invoice_id &&
                    <img src='/pdf-file.png' alt='PDF' onClick={(e) => handlePDF(e, row.supplier_invoice_id)} />
                  }
                </TableCell>
              </TableRow>
            )
          }
          )
          }
          <TableRow >
            <TableCell colSpan={5}></TableCell>
            <TableCell>{toMoney(sum)}</TableCell>
            <TableCell colSpan={4}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
InboundInvoice.propTypes = {
  invoice: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  resetCustomerInvoiceDetail: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    resetCustomerInvoiceDetail
  }, dispatch)

export default connect(null, mapDispatchToProps)(memo(InboundInvoice));
