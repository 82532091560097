import React, { memo, useState } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import PDFViewer from 'mgr-pdf-viewer-react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {
  downloadSupplierInvoicePDF,
} from '../../redux/actions/supplierInvoices';
import {useConfig} from "../../context/configContext";
const PDFPreview = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const { id } = props;
  const [scale, setScale] = useState(8);
  const [PDFURL, setPDFURL] = useState();

  const {
    access_token,
    refresh_token,
    downloadSupplierInvoicePDF
  } = props;
  const handlePDFDownload = (preview = true) => {
    downloadSupplierInvoicePDF({
      access_token,
      refresh_token,
      id: id,
      type: 'pdfSupplierInvoice'
    }).then(res => {
      try {
        if (preview){
          var url = window.URL.createObjectURL(res);
          setPDFURL(url);
        }else{
          let blob = new Blob([res], { type: "application/pdf" });
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.target = '_blank';
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
        }
        } catch (error) {
      }
    })
  }
  if(!PDFURL){
    handlePDFDownload()
  }
  
  return (
    <React.Fragment>
      <div className={classes.controlers}>
        <AddIcon onClick={() => setScale((scale + 1) < 20 ? (scale + 1) : scale)} />
        <RemoveIcon onClick={() => setScale((scale - 1) > 0 ? (scale - 1) : scale)} />

        <OpenInNewIcon onClick={()=>{handlePDFDownload(false)}} style={{marginLeft:10}}/>
      </div>
      <div className={classes.container}>
        {PDFURL &&
          <PDFViewer
            scale={scale / 10}
            hideNavbar={true}
            document={{
              url: PDFURL
            }}
          />
        }
      </div>
    </React.Fragment>
  );

}
PDFPreview.propTypes = {
  type: PropTypes.string.isRequired,
}

const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    downloadSupplierInvoicePDF
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(PDFPreview));