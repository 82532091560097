import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    backContainer: {
      margin: '0px -10px',
      width: 'calc(100% + 20px)',
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 20,
      '& button': {
        maxWidth: 140,
      }
    },
    topPaper: {
      color: '#989898',
      padding: 10,
      marginBottom: 10,
    },
    statusPaper: {
      marginBottom: 10,
      padding: 10,
    },
    fixMargin: {
      width: 'calc(100% + 20px)',
      marginLeft: -10,
    },
    notesPaper: {
      marginBottom: 10,
      padding: 10,
    },
    feesPaper: {
      marginBottom: 10,
      padding: '10px 0px',
    },
    pdfPaper: {
      widht: 'calc(100% - 20px)',
      margin: '0px 10px',
      height: 673,
      padding: 10,
    },
    dismissPaper: {
      marginBottom: 10,
      padding: 10,
      '& .title': {
        color: props.color2,
        fontSize: 20,
        fontWeight: 400,
        marginBottom: 10,
      },
      '& .text': {
        color: '#989898',
        padding: '10px 0px',
        fontSize: 16,
        fontWeight: 200,
      },
      '& .group': {
        display: 'inline-flex',
        width: 'calc(100% + 20px)',
        marginLeft: -10,
        marginBottom: 10,
        '& .input': {
          flexGrow: 1,
          width: 1,
          '& label': {
            height: 16
          }
        },
        '& .action': {
          minWidth: 110
        },
      }
    },
    messageBoxNotConnected: {
      marginRight: 5,
      marginBottom: 0,
    },
    invoiceDataNotConnected: {
      marginLeft: 5,
      marginBottom: 10,
    },
    pdfPaperNotConnected: {
      marginLeft: 5,
      marginRight: 0,
      marginBottom: 10,
    },
    dismissNotConnected: {
      marginLeft: 5,
      marginBottom: 0,
    }
  }));
  return styles();
}
export default theme;

