import React, { memo, useEffect } from 'react';

import { Paper, Grid } from '@material-ui/core';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import CustomerPanel from '../../components/CustomerPanel';
import DialogBox from '../../components/DialogBox';
import { BorderButton } from '../../components/buttons';
import { CustomerLatestInvoices as Table } from '../../components/tables'
import { useHistory, useParams } from 'react-router-dom';
import { setSelectedTab, setInvoiceViewId } from '../../redux/actions/customer'
import { fetchLatestInvoices, fetchFacilityDetail } from '../../redux/actions/customer/customerDetail'
import { fetchConnectionFeesList } from '../../redux/actions/customer/customerConnection'
import translate from '../../misc/applyTranslation'
import {fetchDeliveryTypes} from "../../redux/actions/settings";
import {useConfig} from "../../context/configContext";

const Overview = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const history = useHistory();
  const params = useParams();
  const {
    setSelectedTab,
    setInvoiceViewId,
    fetchLatestInvoices,
    access_token,
    refresh_token,
    invoicesLatest,
    customerData,
    customer_types,
    facilityDetails,
    fetchFacilityDetail, fetchConnectionFeesList,
    fees,
    fetchDeliveryTypes,
    delivery_types
  } = props;
  const handleTableSelect = (id) => {
    setSelectedTab(1);
    setInvoiceViewId(id);
  }

  useEffect(() => {
    fetchFacilityDetail({
      access_token: access_token,
      refresh_token: refresh_token,
      id: params.id,
    });
    fetchConnectionFeesList({
      access_token: access_token,
      refresh_token: refresh_token,
      customer_id: params.id,
    });
    fetchLatestInvoices({
      access_token: access_token,
      refresh_token: refresh_token,
      customer_id: params.id,
    })
    fetchDeliveryTypes({
      access_token: access_token,
      refresh_token: refresh_token
    })
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Grid container className={classes.customerContainer}>
        <Grid item xs={8}>
          <Paper className={classes.paperContainer} elevation={4}>
            <CustomerPanel data={customerData} types={customer_types} fees={fees} deliveryTypes={delivery_types}/>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          {facilityDetails.map((f, index) => (
            <Paper className={`${classes.paperContainer} ${classes.facilityContainer}`} elevation={4} key={index}>
              <div>
                <p><span>{translate('customers/detail', 'facility_label_props_id')}</span>:{f && f.props_id || ""}</p>
                <p><span>{translate('customers/detail', 'facility_label_customer_id')}</span>:{f && f.customer_id || ""}</p>
              </div>
              <p><span>{translate('customers/detail', 'facility_label_facility')}</span>:{f && f.facility || ""}</p>
              <p><span>{translate('customers/detail', 'facility_label_facility_id')}</span>:{f && f.facility_id || ""}</p>
              <p><span>{translate('customers/detail', 'facility_label_yearly_kw')}</span>:{f && f.yearly_kw || ""}</p>
            </Paper>
          ))}
          <Paper className={classes.paperContainer} elevation={4}>
            <DialogBox />
          </Paper>
          <Paper className={classes.paperContainer} elevation={4}>
            <BorderButton onClick={e => history.push(`${params.id}/add-invoice-fee`)} text={translate('', 'edit_fees')} />
          </Paper>
        </Grid>
      </Grid>
      <div className={classes.fixMargins}>
        {/*TODO: Change title with name of company from config file*/}
        <Table elevation={4} data={invoicesLatest} title={translate('customers/detail', 'latest_invoices_table_title')} onClick={(id) => handleTableSelect(id)} />
      </div>
    </React.Fragment>
  );
}
Overview.propTypes = {
  setSelectedTab: PropTypes.func.isRequired,
  setInvoiceViewId: PropTypes.func.isRequired,
  fetchLatestInvoices: PropTypes.func.isRequired,
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
  invoicesLatest: PropTypes.array.isRequired,
  customerData: PropTypes.object.isRequired,
  customer_types: PropTypes.array.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  invoicesLatest: store.customer.invoicesLatest,
  customerData: store.customer.customerData,
  customer_types: store.invoices.customer_types,
  facilityDetails: store.customer.facilityDetails,
  fees: store.customer.fees,
  delivery_types: store.settings.delivery_types

});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    setSelectedTab,
    setInvoiceViewId,
    fetchLatestInvoices,
    fetchFacilityDetail,
    fetchConnectionFeesList,
    fetchDeliveryTypes
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(Overview));
