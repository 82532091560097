import React from 'react';
import theme from './style'
import {FormControlLabel, Checkbox} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useConfig} from "../../../context/configContext";

const SingleCheck = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const {text, value, onChange, style, containerStyle} = props;
  return (
    <FormControlLabel
      className={classes.container}
      style={containerStyle ? containerStyle : {}}
      control={
        <Checkbox
          checked={value || false}
          disableRipple
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.checked)
            }
          }}
          name="checkedB"
          color="primary"
          style={style ? style : {}}
        />
      }
      label={text}
    />
  );
}
SingleCheck.propTypes = {
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
}
export default SingleCheck;