import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    formContainer: {
      '& .gridContainer': {
        display: 'flex',
        justifyContent: 'center',

        '& .gridItemContainer': {
          display: 'inline-flex',
        },
        '& .logoContainer': {
          width: 100,
          height: 100,
          padding: 10,
        },
        '& .inputGroup': {
          display: 'grid',
          alignItems: 'center',
          flexGrow: 1,
        },
      },
      '& .navigationContainer': {
        width: '100%',
        color: '#7f7f7f',
        fontSize: 13,
        fontWeight: 300,
        marginTop: 25,
        textAlign: 'center',
        '& div': {
          cursor: 'pointer',
          width: '100%',
          padding: '10px 0px',
          '&:hover': {
            color: props.color2,
          }
        }
      },
    }
  }));
  return styles();
}
export default theme;

