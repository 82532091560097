import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    datepickerContainer: {
      width: 'calc(100% - 32px)',
      margin: '0px 10px',
      height: 20,
      background: 'white',
      borderRadius: 4,
      padding: '6px 0px 6px 12px',
      display: 'inline-flex',
      alignItems: 'center',

      '-webkit-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
      '-moz-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
      border: '1px solid #ddd',

      '& .MuiFormControl-root': {
        width: 'calc(100% - 34px)',
      },
      '& .MuiInputBase-input': {
        fontSize: '14px !important',
        color: '#989898',
        padding: 0,
        height: 'auto',
        lineHeight: 'unset',
      },
      '& .MuiInputBase-root': {
        height: 'auto',
        lineHeight: 'unset',
      },
      '& .MuiInput-underline:before': {
        border: 'none !important',
        position: 'inherit',
      },
      '& .MuiInput-underline:after': {
        border: 'none !important'
      },
      '& svg': {
        fill: 'white',
        fontSize: 14,
      },
      '& .MuiIconButton-root , .button': {
        background: props.color2,
        padding: 10,
        borderRadius: 0,
        cursor: 'pointer',
      },
      '& .button': {
        borderRadius: '0px 4px 4px 0px ',
      }
    },
  }));
  return styles();
}
export default theme;

