import React, { Fragment, memo } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {
  releaseSupplierInvoice,
  blockSupplierInvoice,
} from '../../../redux/actions/supplierInvoices';

import { ButtonWithLargeLabel } from '../../buttons'
import translate from '../../../misc/applyTranslation';
import { useParams } from 'react-router-dom';
import {useConfig} from "../../../context/configContext";

const StatusPanel = (props) => {
  const params = useParams();
  const config = useConfig();
  const classes = theme(config);
  const {
    access_token,
    refresh_token,
    supplier_invice_data,
  } = props;
  const handleBlock = () => {
    blockSupplierInvoice({
      access_token,
      refresh_token,
      id: params.id
    })
  }
  const handleRelease = () => {
    releaseSupplierInvoice({
      access_token,
      refresh_token,
      id: params.id
    })
  }

  return (
    <Fragment>
      <div className={classes.title}>{translate('supplier-invoices/detail', 'status_panel_title')}</div>
      <div className={classes.borderdStatus}>{translate('supplier-invoices/detail', 'status_panel_ok_status_label')} - <span style={{ color: supplier_invice_data.ok_status.color }}>{supplier_invice_data.ok_status.name}</span></div>
      <div className={classes.simpleStatus}>{translate('supplier-invoices/detail', 'status_panel_release_status_label')} - <span style={{ color: supplier_invice_data.release_status.color }}>{supplier_invice_data.release_status.name}</span></div>
      <div className={classes.fixMargin}>
        <ButtonWithLargeLabel label={translate('supplier-invoices/detail', 'status_panel_block_this_invoice_label')} onClick={handleBlock} />
        <ButtonWithLargeLabel label={translate('supplier-invoices/detail', 'status_panel_release_this_invoice_label')} onClick={handleRelease} />
      </div>
    </Fragment >
  );
}
StatusPanel.propTypes = {
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  supplier_invice_data: store.supplierInvoices.supplierInvoiceDetail.supplier_invice_data,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    blockSupplierInvoice,
    releaseSupplierInvoice,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(StatusPanel));