
import React, { memo, } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { BorderButton, } from '../../components/buttons'
import { TextInput, } from '../../components/inputs'
import { Grid, Paper } from '@material-ui/core';
import { useHistory, useParams, withRouter } from "react-router-dom";
import { setFormData, submitLoginPassword } from '../../redux/actions/login';
import translate from '../../misc/applyTranslation';
import {useConfig} from "../../context/configContext";

const AuthBankID = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const history = useHistory();
  const params = useParams();
  const { setFormData, submitLoginPassword, email, password, } = props;
  const handleForm = (key, value) => {
    setFormData({ key: key, value: value })
  }
  const submitForm = (e) => {
    try { e.preventDefault(); } catch (err) { };
    submitLoginPassword({
      email: email,
      password: password
    }).then(res => {
      if (res) {
        history.push(`${params.lang}`)
      }
    })
  }

  return (
    <form onSubmit={submitForm} className={classes.formContainer}>

      <Grid container className={'gridContainer'}>
        <Grid item xs={11} sm={9} md={7} lg={5} className={'gridItemContainer'}>
          {/* <Paper className={'logoContainer'}>
            <img src={settings.logoAdmin} alt='logo' />
          </Paper> */}
          <div className={'inputGroup'}>
            <TextInput border placeholder={translate('login','placeholder_email')} value={email} onChange={(e) => handleForm('email', e.target.value)} />
            <TextInput border placeholder={translate('login','placeholder_password')} type='password' value={password} onChange={(e) => handleForm('password', e.target.value)} />
            <BorderButton type='submit' text={translate('login','button_login')} onClick={submitForm} />
          </div>
        </Grid>
        {/* <Grid item xs={12} className={'navigationContainer'}>
          <div className={'navigationURL'} onClick={() => history.push(`/${params.lang}`)}>
          {translate('login','link_bankid_login')}
          </div>
          <div className={'navigationURL'} onClick={() => history.push(`/${params.lang}/forgot-password`)}>
          {translate('login','link_password_login')}
          </div>
        </Grid> */}
      </Grid>
    </form>
  )
}
AuthBankID.propTypes = {
  setFormData: PropTypes.func.isRequired,
  submitLoginPassword: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
}
const mapStateToProps = store => ({
  email: store.login.email,
  password: store.login.password,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    setFormData,
    submitLoginPassword,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(memo(AuthBankID)));