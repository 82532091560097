import React, {memo, useEffect, useState} from 'react';
import theme from './style';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import Grid from '@material-ui/core/Grid';
import {TextInput} from '../inputs'
import {SaveIconButton, DeleteIconButton} from '../buttons';
import {
    fetchConnectedUsers,
    disconnectUser,
    connectUser,
} from '../../redux/actions/customer/customerConnection';
import {useParams} from 'react-router-dom';
import ConfirmationModal from '../../components/confirmationWindow';
import {useConfig} from "../../context/configContext";

const CustomerPanel = (props) => {
    const config = useConfig();
    const classes = theme(config);
    const [confirmationWindow, setConfirmationWindow] = useState(false);
    const params = useParams();
    const {
        fetchConnectedUsers,
        connectedUsers,
        access_token,
        refresh_token,
        disconnectUser,
        connectUser,
        user_admin
    } = props;
    const [conUsers, setConUsers] = useState([]);
    const [ssnTemp, setSSNTemp] = useState('');
    const [tempId, setTempId] = useState()
    const fetchData = () => {
        fetchConnectedUsers({
            access_token,
            refresh_token,
            customer: params.id,
            user_admin
        })
    }
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let temp = JSON.parse(JSON.stringify(connectedUsers));
        setConUsers(temp)
        // eslint-disable-next-line
    }, [connectedUsers])

    const handleChange = (val, ind) => {
        let temp = JSON.parse(JSON.stringify(conUsers));
        if (!isNaN(val)) {
            temp[ind].ssn = val;
            setConUsers(temp)
        }
    }
    const handleDelete = () => {
        disconnectUser({
            access_token,
            refresh_token,
            customer: params.id,
            user: tempId,
        })
        handleClose()
    }
    const handleNew = () => {
        connectUser({
            access_token,
            refresh_token,
            customer: params.id,
            ssn: ssnTemp,
        })
        setSSNTemp('')
    }
    const handleClose = () => {
        setTempId()
        setConfirmationWindow(false)
    }
    const handleOpen = (id) => {
        setTempId(id)
        setConfirmationWindow(true)
    }
    const handleTempChange = (e) => {
        let val = e.target.value;
        if (!isNaN(val)) {
            setSSNTemp(val)
        }
    }
    return (
        <React.Fragment>
            <ConfirmationModal open={confirmationWindow} onAbort={handleClose} onAccept={handleDelete}/>
            {conUsers.map((item, index) =>
                <Grid container className={classes.container}>
                    <Grid item xs={8}>
                        <TextInput value={item.ssn} disabled onChange={(e) => handleChange(e.target.value, index)}/>
                    </Grid>
                    <Grid item xs={4} className='inline'>
                        <DeleteIconButton onClick={() => handleOpen(item.user_id)}/>
                    </Grid>
                </Grid>
            )}
            <Grid container className={classes.container}>
                <Grid item xs={8}>
                    <TextInput value={ssnTemp} onChange={handleTempChange}/>
                </Grid>
                <Grid item xs={4} className='inline'>
                    <SaveIconButton onClick={handleNew} disabled={ssnTemp.length !== 12 && ssnTemp.length !== 10}/>
                </Grid>
            </Grid>
        </React.Fragment>
    );

}

CustomerPanel.propTypes = {
    fetchConnectedUsers: PropTypes.func.isRequired,
    disconnectUser: PropTypes.func.isRequired,
    connectedUsers: PropTypes.array.isRequired,
}
const mapStateToProps = store => ({
    access_token: store.login.access_token,
    refresh_token: store.login.refresh_token,
    connectedUsers: store.customer.connectedUsers,
    user_admin: store.login.user_admin
});
const mapDispatchToProps = dispatch =>
    bindActionCreators({
        fetchConnectedUsers,
        disconnectUser,
        connectUser,
    }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(CustomerPanel));