import React, { memo } from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import moment from 'moment';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import translate from '../../../misc/applyTranslation'
import {useConfig} from "../../../context/configContext";
const InvoicesAutoDebit = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const {
    title,
    subTitle,
    description,
    data,
    onClick,
    selected,
  } = props;
  const handleClick = (id, e) => {
    if (onClick) { onClick(id) }
  }
  return (
    <TableContainer component={Paper} className={classes.container} >
      <div className='title'>{title}</div>
      <div className='subtitle'>{subTitle}</div>
      <div className='description'>{description}</div>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>{translate('invoices', 'autodebit_table_header_mark')}</TableCell>
            <TableCell>{translate('invoices', 'autodebit_table_header_name')}</TableCell>
            <TableCell>{translate('invoices', 'autodebit_table_header_inv_day')}</TableCell>
            <TableCell>{translate('invoices', 'autodebit_table_header_due_day')}</TableCell>
            <TableCell>{translate('invoices', 'autodebit_table_header_create_day')}</TableCell>
            <TableCell>{translate('invoices', 'autodebit_table_header_number')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.batch_id} onClick={handleClick.bind(null, row.batch_id)}>
              <TableCell className='check'>
                {selected.includes(row.batch_id) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{moment.unix(row.inv_day).format('YYYY-MM-DD')}</TableCell>
              <TableCell>{moment.unix(row.due_day).format('YYYY-MM-DD')}</TableCell>
              <TableCell>{moment.unix(row.create_date).format('YYYY-MM-DD')}</TableCell>
              <TableCell>{row.number_invoices}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
InvoicesAutoDebit.propTypes = {
  onClick: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
}

export default memo(InvoicesAutoDebit);