import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    title: {
      color: props.color2,
      fontSize: 20,
      fontWeight: 400,
      marginBottom: 10,
    },
    text: {
      color: '#989898',
      padding: '10px 0px',
      fontSize: 16,
      fontWeight: 200,
    },
    group: {
      display: 'inline-flex',
      width: 'calc(100% + 20px)',
      marginLeft: -10,
      marginBottom: 10,
    },
    input: {
      flexGrow: 1,
      width: 1,
      '& label': {
        height: 16
      }
    },
    action: {
      minWidth: 110
    },
  }));
  return styles();
}
export default theme;

