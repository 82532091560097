import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    container: {
      width: 'calc(100% - 20px)',
      marginBottom: 20,
    },
    table: {
      minWidth: 650,
      width: 'calc(100% - 50px)',
      margin: '15px 25px',
      '& .MuiTableCell-head': {
        color: '#989898',
        fontSize: 13,
        fontWeight: 400,
        borderBottomWidth: 2,
      },
      '& .MuiTableCell-body': {
        color: '#989898',
        fontSize: '13px !important',
        fontWeight: 200,
        '& svg': {
          fontSize: '13px !important',
          fill: '#989898'
        }
      },
      '& .MuiTableBody-root > .MuiTableRow-root:last-child > .MuiTableCell-root': {
        // background:'red',
        borderBottom: 'none',
        borderTop: '2px solid rgb(224, 224, 224)',
        color: props.color2,
        fontWeight: 400
      },
      // '& .MuiTableRow-root': {
      //   cursor: 'pointer'
      // }
    },
    importListItem: {
      color: `${props.color2} !important`,
      fontWeight: '400 !important',
      opacity: '0.8',
    },
    tableTitle: {
      color: '#989898',
      fontWeight: 400,
      fontSize: 20,
      textTransform: 'uppercase',
      padding: '20px 20px 10px 20px',
    },
  }));
  return styles();
}
export default theme;

